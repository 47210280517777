import { Flex, Image, Text, Spinner, } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import impact5 from "./impact4.jpg"
import d1 from "./d1.jpg"
import impact1 from "./imp1.jpg"
import impact2 from "./imp2.jpg"
import impact3 from "./imp3.jpg"
import impact4 from "./imp4.jpg"
import Pay from "./pay"
import logo from "./logo.png"
import { Helmet } from "react-helmet"
import bnk1 from "./bnk1.webp"
import bnk2 from "./bnk2.jpg"
import bnk3 from "./bnk3.png"
import bnk4 from "./bnk4.png"
import Toast from "./toast"
import stripe from "./stripe.webp"
import zeffy from "./zeffy.webp"
import pstack from "./pstack.png"



export default function Donate() {
    return (
        <Flex w="100%" direction="column">
            <Helmet>
                <title>Donate - LHO</title>
            </Helmet>
            <Flex w="100%" direction="column">
                <Flex w="100%" h="100vh" bg="#fff">
                    <Flex w={["0", "50%"]}> 
                    </Flex>
                    <Flex w={["100%", "50%"]} h="100%" backgroundColor="#000" backgroundImage={impact5} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="bottom center">
                        <Flex w="100%" h="100%" bg={["rgba(0,0,0,0.5)", "rgba(0,0,0,0.3)"]}></Flex>
                    </Flex>
                </Flex>

                <Flex px={["5", "8%"]} w="100%" position="absolute" top="0" left="0" h="100vh" pb={["30px", "50px" ]}justify="flex-end" direction="column">    
                    <Text data-aos="fade-down" fontSize={["26px", "40px"]} fontWeight="700" color={["#fff", "#000"]}>Support <Text fontWeight="300" as="span">Our Course</Text></Text>
                    <Text mt="2" fontSize={["14px", "13px"]} pr={["0", "55%"]} color={["#fff", "rgb(102, 112, 133)"]}>At LHO, our mission is “Caring for women” by improving their health and well-being generally. </Text>
                    <Flex mt="8" w="100%" flexWrap="nowrap" data-aos="fade-up" direction={["column", "row"]}>
                        {
                            [{name: "Donate", sub: "Over the years, LHO has made a significant impact on the lives of women globally"}, {name: "What we do", sub: "We provide reliable and quality health information, management tips, and support to women in need"}].map((item, index) => (
                                <Flex boxShadow="0px 0px 4px rgba(0,0,0,0.18)" mr="6" bg="rgba(255, 255, 255, 0.8)" w={["100%", "40%"]} mb={["5", "0"]} backdropFilter="blur(12px)" key={index} direction="column" py={["5", "12"]} px={["5", "10"]}>
                                    <Text fontWeight="600" fontSize={["15px", "18px"]}>{item.name}</Text>
                                    <Text mt="2" mb="auto" fontSize={["13px", "15px"]} color="rgb(102, 112, 133)">{item.sub}</Text>
                                    <Flex mt="8" align="center" justify="flex-end">
                                        <Text w="50px" h="2px" bg="#000" mr="2"></Text>
                                        <Flex border="2px solid #000" padding={["10px 30px", "13px 35px"]} cursor="pointer" borderRadius="30px" color="#000" fontWeight="700" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => {
                                            if(index === 0) {
                                                const element = document.getElementById('sec2')
                                                return window.scrollTo({
                                                    top: element ? element.offsetTop - 50 : 0,
                                                    behavior: 'smooth',
                                                })
                                            }
                                            else {
                                                const element = document.getElementById('sec3')
                                                return window.scrollTo({
                                                    top: element ? element.offsetTop - 50 : 0,
                                                    behavior: 'smooth',
                                                })
                                            }
                                        }}>{index === 0 ? "Make a donation" : "See our works"} <Text ml="2">➜</Text></Flex>
                                    </Flex>
                                </Flex>
                            ))
                        }
                    </Flex>
                </Flex>
            </Flex>




            <Flex py={["60px", "80px"]} bg="rgb(250,250,252)" px={["5", "8%"]} direction="column">
                <Text data-aos="fade-down" fontSize={["26px", "40px"]} fontWeight="700">The <Text fontWeight="300" as="span">Need?</Text></Text>
                <Flex align="flex-start" direction={["column", "row"]}>
                    <Flex w={["100%", "50%"]} mt="8" pt={["8", "12"]} borderTop="1px solid rgb(202, 202, 205)" borderRight={["none", "1px solid rgb(202, 202, 205)"]} pr={["0", "12"]} overflowX="hidden">
                        <Flex w="100%" data-aos="fade-right"  backgroundImage={d1} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="top center" h={["300px", "500px"]}></Flex>
                    </Flex>

                    <Flex w={["100%", "50%"]} direction="column" mt={["2", "110px"]}>
                        <Flex w="50%" display={["none", "flex"]} borderTop="1px solid rgb(202, 202, 205)" borderRight={["none", "1px solid rgb(202, 202, 205)"]} h="120px"></Flex>

                        <Flex w={["100%", "90%"]} ml={["0", "10%"]} border={["none", "1px solid rgb(202, 202, 205)"]} borderBottom="none" pt="8" pb="8" px={["0", "8"]} direction="column">
                                {["Globally, there are significant challenges in women's healthcare, including high maternal mortality rates and pervasive gender-based violence. Recent statistics indicate approximately 512 deaths per 100,000 live births, emphasizing the urgent need for improved healthcare access and skilled professionals. Furthermore, 30% of women aged 15-49 have experienced physical violence, and 14% have experienced sexual violence, leading to long-term trauma and impacting their overall well-being."].map((item, index) => (
                                    <Flex direction="column" key={index}>
                                        <Flex justify="center" align="center" fontWeight="700" w="41px" h="40px" fontSize="20px" bg="rgba(0,0,0, .08)" mb="5" borderRadius="100%">01</Flex>
                                                        
                                        <Text data-aos="fade-up" fontSize="30px" fontWeight="700">Why we <Text fontWeight="300" as="span">do it?</Text></Text>
                                        <Flex data-aos="fade-up" fontSize={["14px", "18px"]} mt="4">
                                            {item}
                                        </Flex>
                                    </Flex>
                                ))
                            }
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>



            <Flex direction="column" py={["60px", "80px"]} bg="#fff" align="center" px={["5", "25%"]}>
                <Text data-aos="fade-down" fontSize={["26px", "40px"]} fontWeight="700">Funding <Text fontWeight="300" as="span">Needs</Text></Text>
                <Text mt="2" color="rgb(102, 112, 133)" mb="10" textAlign="center" fontSize={["13px", "14px"]}>To sustain and expand our vital work, we seek funding and support in the amount of <Text as="span" fontWeight="700">$500,000</Text>. This funding will enable us to</Text>
                <Flex direction="column" w="100%" px={["3", "10%"]}>
                    {
                        ["Provide free clinical services to a larger number of women in globally, ensuring they have access to quality healthcare regardless of their financial status.", "Expand our gender-based violence support program, reaching more survivors and providing them with the necessary counseling, medical care, and legal support.", "Develop and implement effective advocacy strategies to drive positive change in women's healthcare policies and programs."].map((item, index) => (
                            <Flex align="center" key={index} mb={index !== 2 && "12"}>
                                <Flex justify="center" align="center" fontWeight="700" w="41px" h="40px" fontSize="20px" bg="rgba(0,0,0, .08)" borderRadius="100%">0{index + 1}</Flex>
                                <Flex flex="1" ml="5" fontSize={["14px", "16px"]} data-aos="fade-up">
                                    {item}
                                </Flex>
                            </Flex>
                        ))
                    }
                </Flex>
            </Flex>


            <Flex w="100%" bg="rgb(250,250,250)" direction="column" px={["0", "12%"]} py={["60px", "80px"]} align="center" id="sec3">
                <Text data-aos="fade-down" fontSize={["26px", "40px"]} fontWeight="700">Our <Text fontWeight="300" as="span">Works</Text></Text>
                <Text color="rgb(102, 112, 133)" mb="12" px={["5", "27%"]} fontSize={["13px", "15px"]} mt={["3", "0"]} textAlign="center">Join us on our road map. LHO has impacted several women's and children's lives globally. Our accomplishments include</Text>

                <Flex w="100%" flexWrap="wrap" justify="space-between" mt="2" data-aos="fade-up" align="flex-start">
                    {
                        [{ini: "Outreach", name: "LHO has made a significant impact on the lives of over 7000 women globally", bg: impact1}, {ini: "Providing", name: "free clinical services to thousands of women, contributing to the reduction of maternal mortality rates and improving health outcomes", bg: impact2}, {ini: "Empowering", name: "survivors of gender-based violence through our comprehensive support programs, aiding their recovery and helping them regain control of their lives", bg: impact3}, {ini: "Influencing", name: "policy changes and raising awareness about women's healthcare issues through our advocacy efforts", bg: impact4}].map((item, index) => (
                            <Flex boxShadow={["none", "0px 0px 10px rgba(0,0,0,0.3)"]} key={index} mb={["1", "5%"]} mt={["0", index === 2 && "-198px"]} w={["100%", "47.5%"]} h={["300px", index === 1 || index === 2 ? "550px" : "350px"]} px={["3", "5"]} py={["3", "5"]} backgroundColor="#000" backgroundImage={item.bg} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="center center">
                                <Flex mt="auto" backdropFilter="blur(12px)" w="100%" bg="rgba(255,255,255,.6)" py="4" px="4" direction="column" color="#000">
                                    <Text fontSize={["16px", "20px"]} fontWeight="600">{item.ini}</Text>
                                    <Text fontSize={["13px", "14px"]}>{item.name}</Text>
                                </Flex>
                            </Flex>
                        ))
                    }
                </Flex>
            </Flex>


            <Flex w="100%" bg="#fff" direction="column" px={["5", "12%"]} py={["60px", "80px"]} align="center" id="sec2">
                <Flex data-aos="fade-down" w={["71px", "81px"]} h={["70px", "80px"]} bgColor="rgba(100, 100, 100, .1)" mb={["3", "4"]} backgroundImage={logo} backgroundSize="65%" backgroundRepeat="no-repeat" borderRadius="100%" backgroundPosition="center center">
                </Flex>
                <Text data-aos="fade-down" fontSize={["26px", "40px"]} fontWeight="700">Donate <Text fontWeight="300" as="span">and Support</Text></Text>
                <Text mt="2" data-aos="fade-up" color="rgb(102, 112, 133)" mb="1" px={["0", "5%"]} textAlign="center" fontSize={["13px", "15px"]}>By supporting Love for Health Organization (LHO), you will be making a tangible impact on the lives of women in Nigeria. Your contribution will help us reduce maternal mortality rates, improve access to healthcare, support survivors of gender-based violence, and empower women through education and advocacy. We will provide regular updates on our programs and outcomes, and ensure transparent reporting on how your support is making a difference.</Text>
                <Text color="rgb(102, 112, 133)" mb="12" px="27%" textAlign="center" cursor="pointer" fontWeight="600" textDecoration="underline" onClick={() => window.open("https://blue-zabrina-85.tiiny.site")}>See proposal</Text>



                <Flex w="100%" justify="space-between" direction={["column", "row"]} flexWrap="wrap">
                    <Flex boxShadow="0 1px 10px 4px rgba(0,0,0,.08)" direction="column" w={["100%", "32%"]} align="center" borderRadius="5px" bg="#fff" py={["10", "12"]} px="6" mb={["5", "0"]}>
                        <Flex w="100px" h="100px" backgroundImage={bnk2} backgroundSize="cover" backgroundRepeat="no-repeat" borderRadius="100%" backgroundPosition="bottom center"></Flex>
                        <Text fontWeight="bold" mt="6" textAlign="center">Zenith Bank</Text>
                        <Text mt="3" textAlign="center">1229255323</Text>
                        <Text mt="3" textAlign="center">Love for health and gender equality</Text>
                        <Text mt="3" textAlign="center"><Text as="span" fontWeight="bold">Purpose of transfer:</Text> funding/donation/grants </Text>
                        <Flex w="100%" mt="auto"></Flex>
                        <Flex w="100%" mt="6" justify="center" align="center">
                            <Flex w="100%" bg="#fff" border="2px solid #000" padding={["10px 30px", "14px 32px"]} cursor="pointer" borderRadius="30px" color="#000" fontWeight="600" align="center" justify="center" fontSize={["13px", "14px"]} transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => {
                                navigator.clipboard.writeText("1229255323")
                                Toast("Zenith Account number copied to copied to your clipboard!!", "success")
                            }}>Copy Account Number <Text ml="2"><i className="mdi mdi-content-copy"></i></Text></Flex>
                        </Flex>
                    </Flex>

                    <Flex boxShadow="0 1px 10px 4px rgba(0,0,0,.08)" direction="column" w={["100%", "32%"]} align="center" borderRadius="5px" bg="#fff" py={["10", "12"]} px="6" mb={["5", "0"]}>
                        <Flex w="100px" h="100px" backgroundImage={bnk1} backgroundSize="cover" backgroundRepeat="no-repeat" borderRadius="100%" backgroundPosition="bottom center"></Flex>
                        <Text fontWeight="bold" mt="6" textAlign="center">Providus Bank</Text>
                        <Text mt="3" textAlign="center">1304537058</Text>
                        <Text mt="3" textAlign="center">Love For Healthcare</Text>
                        <Text mt="3" textAlign="center"><Text as="span" fontWeight="bold">Purpose of transfer:</Text> funding/donation/grants </Text>
                        <Flex w="100%" mt="auto"></Flex>
                        <Flex w="100%" mt="6" justify="center" align="center">
                            <Flex w="100%" bg="#fff" border="2px solid #000" padding={["10px 30px", "14px 32px"]} cursor="pointer" borderRadius="30px" color="#000" fontWeight="600" align="center" justify="center" fontSize={["13px", "14px"]} transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => {
                                navigator.clipboard.writeText("1304537058")
                                Toast("Providus Account number copied to copied to your clipboard!!", "success")
                            }}>Copy Account Number <Text ml="2"><i className="mdi mdi-content-copy"></i></Text></Flex>
                        </Flex>
                    </Flex>

                    <Flex boxShadow="0 1px 10px 4px rgba(0,0,0,.08)" direction="column" w={["100%", "32%"]} align="center" borderRadius="5px" bg="#fff" py={["10", "12"]} px="6">
                        <Flex w="100px" h="100px" backgroundImage={bnk3} backgroundSize="cover" backgroundRepeat="no-repeat" borderRadius="0%" backgroundPosition="bottom center"></Flex>
                        <Text fontWeight="bold" mt="6" textAlign="center">First Merchant Bank</Text>
                        <Text mt="3" textAlign="center">Account number: 102051564 </Text>
                        <Text mt="3" textAlign="center"> LHO</Text>
                        <Text mt="3" textAlign="center">Routine: 074900657 </Text>
                        <Text mt="3" textAlign="center"><Text as="span" fontWeight="bold">Purpose of transfer:</Text> funding/donation/grants </Text>
                        <Flex w="100%" mt="auto"></Flex>
                        <Flex w="100%" mt="6" justify="center" align="center">
                            <Flex w="100%" bg="#fff" border="2px solid #000" padding={["10px 30px", "14px 32px"]} cursor="pointer" borderRadius="30px" color="#000" fontWeight="600" align="center" justify="center" fontSize={["13px", "14px"]} transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => {
                                navigator.clipboard.writeText("102051564")
                                Toast("First Merchant Bank Account number copied to copied to your clipboard!!", "success")
                            }}>Copy Account Number <Text ml="2"><i className="mdi mdi-content-copy"></i></Text></Flex>
                        </Flex>
                    </Flex>


                    <Flex mt={["0", "7"]} mx="auto" boxShadow="0 1px 10px 4px rgba(0,0,0,.08)" direction="column" w={["100%", "32%"]} align="center" borderRadius="5px" bg="#fff" py={["10", "12"]} px="6">
                        <Flex w="100px" h="100px" backgroundImage={bnk4} backgroundSize="100%" backgroundRepeat="no-repeat" borderRadius="0%" backgroundPosition="center center"></Flex>
                        <Text fontWeight="bold" mt="6" textAlign="center">Citi Bank</Text>
                        <Text mt="3" textAlign="center">Account number: 11637193365 </Text>
                        <Text mt="3" textAlign="center"> LHO</Text>
                        <Text mt="3" textAlign="center">Routine: 271070801 </Text>
                        <Text mt="3" textAlign="center"><Text as="span" fontWeight="bold">Purpose of transfer:</Text> funding/donation/grants </Text>
                        <Flex w="100%" mt="auto"></Flex>
                        <Flex w="100%" mt="6" justify="center" align="center">
                            <Flex w="100%" bg="#fff" border="2px solid #000" padding={["10px 30px", "14px 32px"]} cursor="pointer" borderRadius="30px" color="#000" fontWeight="600" align="center" justify="center" fontSize={["13px", "14px"]} transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => {
                                navigator.clipboard.writeText("11637193365")
                                Toast("Citi Bank Account number copied to copied to your clipboard!!", "success")
                            }}>Copy Account Number <Text ml="2"><i className="mdi mdi-content-copy"></i></Text></Flex>
                        </Flex>
                    </Flex>

                    


                </Flex>

                <Flex w="100%" align="center" mt="10" mb="8" color="rgb(102, 112, 133)"><Flex flex="1" bg="rgb(102, 112, 133)" h="1px"></Flex><Text textAlign="center" fontSize="24px" mx="8">Or</Text><Flex flex="1" bg="rgb(102, 112, 133)" h="1px"></Flex></Flex>

                <Flex w="100%" justify="space-between" align="center" direction={["column", "row"]}>
                    <Flex w={["100%", "45%"]} align="center" border="2px solid rgb(102, 112, 133)" cursor="pointer" px="4" py="3" onClick={() => window.open("https://donate.stripe.com/28obM65dbfbE1RSbII")} borderRadius="8px">
                        <Image h="40px" src={stripe} />
                        <Text ml="4" fontWeight="bold">Donate with Stripe</Text>
                    </Flex>

                    <Flex w={["100%", "45%"]} mt={["8", "0"]} align="center" border="2px solid rgb(102, 112, 133)" cursor="pointer" px="4" py="3" onClick={() => window.open("https://www.zeffy.com/en-US/donation-form/b88abad5-bcb0-4a3a-8d8a-f14b97eb51d6")} borderRadius="8px">
                        <Image h="40px" src={zeffy} />
                        <Text ml="4" fontWeight="bold">Donate on Zeffy</Text>
                    </Flex>
                </Flex>
                <Flex w="100%" mt="8" px={["0", "27.5%"]}>
                    <Flex w="100%" align="center" border="2px solid rgb(102, 112, 133)" cursor="pointer" px="8" py="3" onClick={() => window.open("https://paystack.com/pay/love4health")} borderRadius="8px">
                        <Image h="40px" src={pstack} />
                        <Text ml="4" fontWeight="bold">Donate with Paystack</Text>
                    </Flex>
                </Flex>


                <Flex w="100%" align="center" mt="10" mb="8" color="rgb(102, 112, 133)"><Flex flex="1" bg="rgb(102, 112, 133)" h="1px"></Flex><Text textAlign="center" fontSize="24px" mx="8">Or</Text><Flex flex="1" bg="rgb(102, 112, 133)" h="1px"></Flex></Flex>

                <Pay />
            </Flex>


        </Flex>
    )
}