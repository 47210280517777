import { Flex, Image, Text, Spinner, } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import logo from "./logo.png"
import aboutCover from "./aboutCover.jpg"
import about1 from "./about1.jpg"
import about2 from "./about2.png"
import vid1 from "./vid/a3.mp4"
import s1 from "./s1.jpg"
import s2 from "./s2.jpg"
import s3 from "./s3.jpg"
import impact1 from "./impact1.jpg"
import impact2 from "./impact2.jpg"
import impact3 from "./impact3.jpg"
import impact5 from "./impact5.jpg"
import globalImage from "./global.svg"
import tone from "./tone.mp3"
import { useHistory } from "react-router-dom"
import u1 from "./u2.jpg"
import {Helmet} from "react-helmet";


export default function AboutUs({ setScreen }) {
    const history = useHistory()

    return (
        <Flex w="100%" direction="column">
             <Helmet>
                <title>About Us - LHO</title>
            </Helmet>
            <Flex w="100%" minH="100vh" backgroundColor="#000" backgroundImage={aboutCover} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition={["center right", "center center"]}>
                <Flex w="100%" h="100%" bg="rgba(0,0,0,0.7)" direction="column" justify="center" px={["5", "8%"]} align="center" color="#fff" py="50px">
                    <Flex data-aos="fade-down" w={["71px", "81px"]} h={["70px", "80px"]} bgColor="rgba(100, 100, 100, .4)" mb={["3", "4"]} backgroundImage={logo} backgroundSize="65%" backgroundRepeat="no-repeat" borderRadius="100%" backgroundPosition="center center">
                    </Flex>
                    <Text data-aos="fade-down" fontSize={["30px", "60px"]} fontWeight="700">Who <Text fontWeight="300" as="span">are we?</Text></Text>
                    <Text mt={["5", "0"]} fontSize={["15px", "20px"]} data-aos="fade-up" color="rgb(240,240,240)" textAlign="center" px={["0", "15%"]}>Love for Health Organization (LHO) is a team of passionate health professionals dedicated to improving the health and well-being of women. Our organization was founded in 2008. Since then, we have grown into a team of health professionals committed to <Text fontWeight="700" as="span">“caring for women”</Text> providing them with access to quality healthcare and advocating for their rights and quality access to improved healthcare services in their local communities 🌎.</Text>
                    <Flex mt="10" align="center" data-aos="fade-up">
                        <Text w="50px" h="2px" bg="#fff" mr="2"></Text>
                        <Flex border="2px solid #fff" padding={["10px 30px", "18px 35px"]} cursor="pointer" borderRadius="30px" color="#fff" fontWeight="700" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => {
                            var a = document.getElementById('myTone')
                            a.play()
                            const element = document.getElementById('sec1')
                            return window.scrollTo({
                                top: element ? element.offsetTop - 50 : 0,
                                behavior: 'smooth',
                            })
                        }}>Enter our world<Text ml="2">➜</Text></Flex>
                        <Text w="50px" h="2px" bg="#fff" ml="2"></Text>
                    </Flex>
                </Flex>
            </Flex>



            <Flex py={["60px", "80px"]} bg="#fff" px={["5", "8%"]} direction="column" id="sec1">
                <Text data-aos="fade-down" fontSize={["26px", "40px"]} fontWeight="700">What <Text fontWeight="300" as="span">we do!</Text></Text>
                <Flex align="flex-start" direction={["column", "row"]}>
                    <Flex w={["100%", "50%"]} mt="8" pt={["8", "12"]} borderTop="1px solid rgb(202, 202, 205)" borderRight={["none", "1px solid rgb(202, 202, 205)"]} pr={["none", "12"]} overflowX="hidden">
                        <Flex w="100%" data-aos="fade-right"  backgroundImage={about1} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="top center" h={["250px", "600px"]}></Flex>
                    </Flex>

                    <Flex w={["100%", "50%"]} direction="column" mt={["0", "200px"]}>
                        <Flex display={["none", "flex"]} w="60%" borderTop="1px solid rgb(202, 202, 205)" borderRight="1px solid rgb(202, 202, 205)" h="200px"></Flex>

                        <Flex w={["100%", "90%" ]}ml={["0", "10%"]} border={["none", "1px solid rgb(202, 202, 205)"]} borderBottom="none" pt="8" pb="8" px={["0", "8"]} direction="column">
                            {
                                ["Provide free clinical services to improve health care access and outcomes", "Support survisors of gender-based violence through counselling, care and legal aid", "Advocate for reproductive health, gender equality and women's right", "Create safe spaces for sharing, support and resources", "Collaborate to improve healthcare and prevent gender-based violence"].map((item, index) => (
                                    <Flex align="center" key={index} mb={index !== 4 && "12"}>
                                        <Flex justify="center" align="center" fontWeight="700" w="41px" h="40px" fontSize="20px" bg="rgba(0,0,0, .08)" borderRadius="100%">0{index + 1}</Flex>
                                        <Flex flex="1" ml="5" fontSize={["14px", "16px"]} data-aos="fade-up">
                                            {item}
                                        </Flex>
                                    </Flex>
                                ))
                            }
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>

            <Flex py={["60px", "80px"]} mt={["50px", "150px"]} bg="rgba(35, 166, 35, .08)" px={["5", "8%"]} align="flex-start" direction={["column-reverse", "row"]}>
                <Flex w={["100%", "50%"]} pr={["0", "12"]} direction="column" mt={["12", "0"]}>
                    <Text textAlign={["center", "left"]} mb={["8", "10"]} data-aos="fade-down" fontSize={["26px", "40px"]} fontWeight="700">Why We <Text fontWeight="300" as="span">do it!</Text></Text>
                    {
                        ["Women face significant challenges when it comes to accessing quality healthcare and support. According to recent statistics from the United Nations and UNICEF, Nigeria has one of the highest maternal mortality rates in the world, with approximately 512 deaths per 100,000 live births. This is due in part to the lack of access to healthcare facilities and skilled healthcare professionals, particularly in rural areas.", "Gender-based violence is also a pervasive issue, with a recent survey showing that 30% of women aged 15-49 have experienced physical violence, and 14% have experienced sexual violence. This has a significant impact on women's health and well-being, and can result in long-term physical and psychological trauma.", "At Love for Health Organization (LHO), we believe that every woman deserves access to quality healthcare and support, regardless of their financial status. We are passionate about improving the health and well-being of women and we are committed to providing free clinical services to address and ameliorate the struggles that women face."].map((item, index) => (
                            <Flex direction="column" key={index} mb="10" align={["center", "flex-start"]}>
                                 <Flex justify="center" align="center" fontWeight="700" w="41px" h="40px" fontSize="20px" bg="rgba(0,0,0, .08)" borderRadius="100%" mb="4">0{index + 1}</Flex>
                                <Text fontSize={["14px", "16px"]} textAlign={["center", "left"]} data-aos="fade-up">
                                    {item}
                                </Text>
                            </Flex>
                        ))
                    }
                </Flex>
                <Flex w={["100%", "50%"]} direction="column">
                    <Flex w="100%" px={["20%", "0"]} data-aos="fade-up"><Image w="100%" src={about2} borderRadius="8px" mt={["-150px", "-280px"]} /></Flex>
                    <Flex mt={["8", "12"]} align="center" data-aos="fade-up" justify="center">
                        <Text w={["20px", "50px"]} h="2px" bg="#000" mr="2"></Text>
                        <Flex border="2px solid #000" padding={["10px 30px", "18px 35px"]} cursor="pointer" borderRadius="30px" color="#000" fontWeight="700" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => window.open("https://blue-zabrina-85.tiiny.site")}>See Our Proposal <Text ml="2">➜</Text></Flex>
                        <Text w={["20px", "50px"]} h="2px" bg="#000" ml="2"></Text>
                    </Flex>
                </Flex>
            </Flex>


            
            <Flex w="100%" h="100%" bg="#fff" px={["5", "8%"]} align="center" color="#000" py={["60px", "100px"]} direction={["column", "row"]}>
                <Flex w={["100%", "50%"]} data-aos="fade-up">
                    <video loop muted playsInline autoPlay style={{ background: "#000", width: "100%", borderRadius: "10px"}}><source src={vid1} /></video>
                </Flex>
                <Flex mt={["5", "0"]} w={["100%", "50%"]} direction="column" pl={["0", "12"]}>
                    <Flex justify="center" mb="3" align="center" w="51px" h="50px" fontSize="25px" bg="rgba(0,0,0, .08)" borderRadius="100%" cursor="pointer" transition="150ms ease-in-out" _hover={{ transform: "translate(0, -4px)" }}>
                        <i className="mdi mdi-human-handsdown"></i>
                    </Flex>
                    <Text data-aos="fade-down" fontSize={["27px", "40px"]} fontWeight="700">Our <Text fontWeight="300" as="span">Mission</Text></Text>
                    
                    <Text fontSize={["14px", "17px"]} mt="2" data-aos="fade-up" color="rgb(102, 112, 133)">Our mission is to create a society where women and the girl child can thrive, and we believe that our work can make a real difference in the lives of women and their families. By providing free clinical services and support, we can help to reduce maternal mortality rates and improve the health outcomes of women and their children. We are also committed to fighting against gender-based violence, including rape and discrimination, and advocating for the rights of women and girls. We believe that through our work, we can create a brighter future for women.</Text>
                </Flex>
            </Flex>

            
            <Flex w="100%" bg="rgb(250,250,252)" direction="column" px={["5", "8%"]} py={["60px", "100px"]}  align="center">
                <Text data-aos="fade-down" fontSize={["27px", "60px"]} fontWeight="700">Our <Text fontWeight="300" as="span">Services</Text></Text>

                <Flex w="100%" justify="space-between" mt={["40px", "60px"]} data-aos="fade-up" direction={["column", "row"]}>
                    {
                        [{name: "Free Clinical Services for Women", sub: "At Love for Health Organization (LHO), we provide free clinical services to address the healthcare struggles faced by women globally.", dp: s1}, {name: "Gender-Based Violence Support", sub: "We recognize the pervasive issue of gender-based violence globally and the significant impact it has on women's health and well-being.", dp: s2}, {name: "Advocacy and Education", sub: "We believe that education and advocacy are key to creating a society where women and the girl child can thrive", dp: s3}].map((item, index) => (
                            <Flex bg="rgba(255, 255, 255, .23)" borderRadius="8px" mb={["8", "0"]} w={["100%", "30%"]} direction="column" key={index}>
                                <Flex justify="center" align="center" fontWeight="700" w="41px" h="40px" fontSize="20px" bg="rgba(0,0,0, .08)" borderRadius="100%">0{index + 1}</Flex>
                                <Flex mt="5" w="100%" direction="column">
                                    <Text mb="1" fontWeight="600">{item.name}</Text>
                                    <Text mb="5" fontSize="13px" color="rgb(102, 112, 133)">{item.sub}</Text>
                                    <Flex w="100%" h={["250px", "300px"]} backgroundImage={item.dp} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="center center" borderRadius="8px" />
                                </Flex>
                                <Flex mt="auto">
                                    <Flex w="100%" mt="6" justify="flex-end" align="center">
                                        <Text w="50px" h="2px" bg="#000" mr="2"></Text><Flex bg="#fff" border="2px solid #000" padding={["10px 30px", "14px 32px"]} cursor="pointer" borderRadius="30px" color="#000" fontWeight="600" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => {
                                            setScreen(index)
                                            history.push("/our-services")
                                        }}>Request <Text ml="2">➜</Text></Flex>
                                    </Flex>
                                </Flex>
                            </Flex>
                        ))
                    }
                </Flex>
            </Flex>
            
            

            <Flex w="100%" bg="#fff" direction="column" px={["0", "8%"]} py={["60px", "100px"]}  align="center">
                <Text data-aos="fade-down" fontSize={["24px", "40px"]} fontWeight="700">Impact and <Text fontWeight="300" as="span">Success Stories</Text></Text>
                <Text color="rgb(102, 112, 133)" mb={["10", "12"]} mt={["2", "0"]} px={["7", "27%"]} textAlign="center">Join us on our road map. LHO has impacted several women's and children's lives globally. Our accomplishments include</Text>

                <Flex w="100%" flexWrap="wrap" justify="space-between" mt={["0", "2"]} data-aos="fade-up">
                    {
                        [{ini: "Outreach", name: "LHO has made a significant impact on the lives of women globally", bg: impact5}, {ini: "Providing", name: "free clinical services to thousands of women, contributing to the reduction of maternal mortality rates and improving health outcomes", bg: impact2}, {ini: "Empowering", name: "survivors of gender-based violence through our comprehensive support programs, aiding their recovery and helping them regain control of their lives", bg: impact3}, {ini: "Influencing", name: "policy changes and raising awareness about women's healthcare issues through our advocacy efforts", bg: impact1}].map((item, index) => (
                            <Flex key={index} mb={["2", "1"]} w={["100%", "49.8%"]} h="450px" px={["3", "5"]} py={["3", "5"]}backgroundColor="#000" backgroundImage={item.bg} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="center center">
                                <Flex mt="auto" w={["100%", "80%"]} bg="rgba(0,0,0,0.6)" color="#fff" py={["3", "4"]} px={["3", "4"]} direction="column">
                                    <Text fontSize="20px" fontWeight="600">{item.ini}</Text>
                                    <Text fontSize="14px">{item.name}</Text>
                                </Flex>
                            </Flex>
                        ))
                    }
                </Flex>

                <Flex pt={["60px", "100px"]} justify="center" align="center" direction="column">
                    <Text data-aos="fade-down" fontSize={["26px", "40px"]} fontWeight="700">Business <Text fontWeight="300" as="span">Plan</Text></Text>
                    <Text mt="2" data-aos="fade-up" color="rgb(102, 112, 133)" mb="4" px={["5", "5%"]} textAlign="center" fontSize={["13px", "15px"]}>Provided below is a link to view our business plan for Love for Health Organization.</Text>
                    <Text color="rgb(102, 112, 133)" mb="12" px="27%" textAlign="center" cursor="pointer" fontWeight="600" textDecoration="underline" onClick={() => window.open("https://drive.google.com/file/d/1rGgRmrjr4ai5RADEROQpkcVWD0rHSAmS/view?usp=sharing")}>See Business Plan</Text>
                </Flex>

                <Flex pt={["60px", "100px"]} px={["5", "8%"]} justify="space-between" direction={["column", "row"]}>
                    <Flex w={["100%", "40%" ]} borderRadius={["12px 12px 0 0", "12px 0 0 12px"]} boxShadow="0 1px 10px 4px rgba(0,0,0,.08)" backgroundColor="#000" backgroundImage={u1} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="center center" data-aos="fade-down" py={["100px", "0"]}></Flex>

                    <Flex w={["100%", "58%"]} mt={["3", "0"]} borderRadius={["0 0 12px 12px", "0 12px 12px 0"]} direction="column" px={["6", "12"]} py={["10", "12" ]} boxShadow="0 1px 10px 4px rgba(0,0,0,.08)" data-aos="fade-up">
                        <Text data-aos="fade-down" fontSize={["26px", "40px"]}><Text fontWeight="700" as="span">Register</Text> Today!</Text>
                        <Text mt="3" mb={["0", "8"]} fontSize={["13px", "14px"]} color="rgb(102, 112, 133)" pr={["0", "20%"]}>You can register on Love For Health to have access to our amazing services for free - prenantal care counselling, mental health services, women health care education, advocacy and empowerment.</Text>
                        <Flex w="100%" mt="12" justify="flex-end" align="center" data-aos="fade-up">
                            <Text w="50px" h="2px" bg="#000" mr="2"></Text><Flex bg="#fff" border="2px solid #000" padding={["10px 30px", "14px 32px"]} cursor="pointer" borderRadius="30px" color="#000" fontWeight="600" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => history.push("/register")}>Get Started <Text ml="2">➜</Text></Flex>
                        </Flex>
                    </Flex>
                </Flex>


                <Flex w="100%" borderTop="1px solid rgb(202, 202, 205)" direction="column" mt="80px" pt="50px" align="center" px={["5", "0"]}>  
                    <Text data-aos="fade-up" fontSize={["26px", "40px"]} fontWeight="700">Join Us o<Text fontWeight="300" as="span">r Support Us</Text></Text>

                    <Flex mt={["8", "12" ]}align="center" data-aos="fade-up" justify="center">
                        <Text w={["20px", "50px"]} h="2px" bg="#000" mr="2"></Text>
                        <Flex fontSize={["12px", "15px"]} border="2px solid #000" padding={["10px 15px", "18px 35px"]} cursor="pointer" borderRadius="30px" color="#000" fontWeight="700" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => history.push("/contact-us")}>Reach Out <Text ml="2">➜</Text></Flex>

                        <Flex display={["none", "flex"]} ml={["2", "6"]} fontSize={["12px", "15px"]} border="2px solid #000" padding={["10px 10px", "18px 35px"]} cursor="pointer" borderRadius="30px" color="#000" fontWeight="700" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => window.open("https://blue-zabrina-85.tiiny.site")}>See Our Proposal <Text ml="2">➜</Text></Flex>

                        <Flex ml={["3", "6"]} fontSize={["12px", "15px"]} border="2px solid #000" padding={["10px 15px", "18px 35px"]} cursor="pointer" borderRadius="30px" color="#000" fontWeight="700" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => history.push("/donations")}><Text display={["none", "flex"]}>Make a Donation</Text><Text display={["flex", "none"]}>Donate</Text> <Text ml="2">➜</Text></Flex>
                        <Text w={["20px", "50px"]} h="2px" bg="#000" ml="2"></Text>
                    </Flex>
                </Flex>
            </Flex>



            <Flex py={["40px", "100px"]}  px={["5", "12%"]} bgColor="#000" color="#fff" align="center" backgroundImage={globalImage} direction={["column", "row"]} backgroundRepeat="no-repeat" backgroundSize={["120%", "40%"]} backgroundPosition="bottom right">
                <Flex data-aos="fade-up" w={["120px", "300px"]} h={["120px", "300px"]} bgColor="rgba(255, 255, 255, .25)" backgroundImage={logo} pt="12" backgroundSize="65%" backgroundRepeat="no-repeat" borderRadius="100%" backgroundPosition="center center">
                </Flex>
                <Flex pl={["0", "12"]} mt={["5", "0"]} flex="1" direction="column">
                    <Text data-aos="fade-down" fontSize={["26px", "40px"]} fontWeight="700">Our <Text fontWeight="300" as="span">Team</Text></Text>
                    <Text mt="8" data-aos="fade-up">
                        <Text color="rgb(240,240,240)">Our dedicated team consists of experienced health professionals, including doctors, nurses, counsellors, and advocates. Together, we bring a wealth of knowledge and expertise to address the healthcare struggles faced by women in globally</Text>
                        <Text fontSize={["16px", "18px"]} mt="8" fontWeight="600">Dr. Haroun Yusuf Ademola (Founder)</Text>
                    </Text>
                    <Flex mt="8" align="center" data-aos="fade-up">
                        <Text w="50px" h="2px" bg="#fff" mr="2"></Text>
                        <Flex border="2px solid #fff" padding={["10px 30px", "18px 35px"]} cursor="pointer" borderRadius="30px" color="#fff" fontWeight="700" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => history.push("/booking")}>Book Dr. Haroun<Text ml="2">➜</Text></Flex>
                    </Flex>

                    
                    <Text mt="12" fontSize="20px" fontWeight="700">Other <Text fontWeight="300" as="span">Team Members</Text></Text>
                    <Text mt="5" data-aos="fade-up" fontSize={["16px", "18px"]}>
                        <Text fontWeight="600" as="span" mr="6">➜ Omotayo Tebi</Text>
                        <Text fontWeight="600" as="span" mr="6">➜ Chidinma Makuachukwu</Text>
                        <Text fontWeight="600" as="span" mr="6">➜ Babajide Adeokin</Text>
                        <Text fontWeight="600" as="span" mr="6">➜ Omotara Akanni</Text>
                        <Text fontWeight="600" as="span" mr="6">➜ Oluwakayode Donald</Text>
                        <Text fontWeight="600" as="span">➜ Persia Queen</Text>
                    </Text>
                </Flex>
            </Flex>
            
            <audio style={{ width: "0", height: "0", visibility: "hidden"}} src={tone} id="myTone" />
        </Flex>
    )
}