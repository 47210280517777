import { useEffect, useState } from "react";
import {
    PayPalScriptProvider,
    PayPalButtons,
    usePayPalScriptReducer
} from "@paypal/react-paypal-js";
import { Flex, Text, Image } from "@chakra-ui/react";
import Toast from "./toast";
import pBtn from "./pBtn.png"
import cBtn from "./cBtn.png"

/*const ButtonWrapper = ({ currency, amount }) => {
    const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

    useEffect(() => {
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                currency: currency,
            },
        });
    }, [amount, currency]);

 
     return (
     <PayPalButtons
        fundingSource="paypal"
        style={{"layout":"vertical","label":"donate"}}
        disabled={false}
        createOrder={(data, actions) => {
            return actions.order
                .create({
                    purchase_units: [
                        {
                            amount: {
                                value: amount,
                                breakdown: {
                                    item_total: {
                                        currency_code: "USD",
                                        value: amount,
                                    },
                                },
                            },
                            items: [
                                {
                                    name: "donation",
                                    quantity: "1",
                                    unit_amount: {
                                        currency_code: "USD",
                                        value: amount,
                                    },
                                    category: "DONATION",
                                },
                            ],
                        },
                    ],
                })
                .then((orderId) => {
                    Toast("Thanks for making the world a better place", "success")
                    return orderId;
                });
        }}
        onApprove={function (data, actions) {
            return actions.order.capture().then(function () {
                Toast("Thank you! Payment recieved. Thanks for making the world a better place", "success")
            });
        }}
    />
     );
}*/

 export default function Pay() {
    const [amount, setAmount] = useState("")
    return (
        <Text w="100%" align="center" px="auto" maxW="600px">
            <Flex direction="column" mb="5">
                <Text mb="2" textAlign="left" fontWeight="500">Enter an amount to donate</Text>
                <input style={{ width: "100%", border: "1px solid #000", padding: "15px 35px", borderRadius: "8px" }} placeholder="$ Amount" onInput={(e) => setAmount(e.target.value)} type="number" />
            </Flex>
            <Flex align="center" justify="space-between" direction={["column", "row"]}>
                <Image borderRadius="8px" w={["100%", "45%"]} src={pBtn} cursor="pointer" onClick={() => {
                    if(amount !== "") {
                        window.open("https://www.paypal.me/HAYUDS2U")
                    }
                    else {
                        Toast("Kindly enter an amount to donate", "error")
                    }
                }} />

                <Image borderRadius="8px" mt={["4", "0"]} w={["100%", "45%"]} src={cBtn} cursor="pointer" onClick={() => {
                    if(amount !== "") {
                        window.open("https://cash.app/$hayuds2u")
                    }
                    else {
                        Toast("Kindly enter an amount to donate", "error")
                    }
                }} />
            </Flex>
            {/*<PayPalScriptProvider
                options={{
                    "client-id": "AVd9B3Nn2vAyUzxLPJGsjAgMcYrhmca2WYDzZzEXEhDGEDoyS5kb6bRrAKz8F8wQ2lr5d9NXmhaLAcQh",
                    components: "buttons",
                    currency: "USD"
                }}
            >
                <ButtonWrapper
                    currency={"USD"}
                    amount={amount}
                />
            </PayPalScriptProvider>*/}
        </Text>
    );
 }