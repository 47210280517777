import { useState, useEffect, useRef } from 'react';
import { 
	Flex,
	Image,
	Text,
  	ChakraProvider,
	Spinner,
} from "@chakra-ui/react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
  } from "react-router-dom";
import ScrollToTop from "./sc";
import "./App.scss";
import "./mdi/css/materialdesignicons.css";
import Aos from "aos";
import "aos/dist/aos.css";
import axios from 'axios';
import Toast from "./toast"
import Landing from './landing';
import Nav from './nav';
import Footer from './footer';
import AboutUs from './about';
import Services from './services';
import Contact from './contact';
import Donate from './donate';
import Booking from './booking';
import Articles from './articles';
import TweetsArticles from './tw';
import SignIn from './signin';
import Register from './register';
import Dashboard from './dashboard';

function App() {
	useEffect(() => {
		Aos.init({ duration: 1000 })
	}, [])

	const [bookNo, setBookNo] = useState(0)
	const [screen, setScreen] = useState(0)

	const [isLogged, setIsLogged] = useState(sessionStorage.getItem("lho-login") === null ? false : true)

	return (
		<ChakraProvider>
			<Flex w="100%" minH="100vh" fontSize="15px">
				<Router>				
					<ScrollToTop>
						<Flex w="100%" direction="column">
							<Nav isLogged={isLogged} />
							<Switch>
								<Route exact path="/"><Landing /></Route>
								<Route path="/about-us"><AboutUs setScreen={setScreen} /></Route>
								<Route path="/our-services"><Services screen={screen} setScreen={setScreen} setBookNo={setBookNo} /></Route>
								<Route path="/contact-us"><Contact /></Route>
								<Route path="/donations"><Donate /></Route>
								<Route path="/articles"><Articles /></Route>
								<Route path="/social"><TweetsArticles /></Route>
								<Route path="/booking"><Booking bookNo={bookNo} setBookNo={setBookNo} isLogged={isLogged} /></Route>
								<Route path="/sign-in"><SignIn isLogged={isLogged} setIsLogged={setIsLogged} /></Route>
								<Route path="/register"><Register isLogged={isLogged} setIsLogged={setIsLogged} /></Route>
								<Route path="/dashboard"><Dashboard isLogged={isLogged} setIsLogged={setIsLogged} /></Route>
								<Route><Landing /></Route>
							</Switch>
							<Footer />
						</Flex>
					</ScrollToTop>
				</Router>
			</Flex>
		</ChakraProvider>
	)
}

export default App;
