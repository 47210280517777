import { Flex, Image, Text, } from "@chakra-ui/react"
import { useEffect, useState, } from "react"
import { useHistory, useLocation } from "react-router-dom";


export default function Nav({ isLogged }) {
    const history = useHistory()
    const location = useLocation() //location.pathname.includes
    const [menu, setMenu] = useState(false)

    const [scNav, setScNav] = useState(false)
    const handleNavScroll = () => {
        const position = window.pageYOffset;
        if (position > 680) {
            setScNav(true)
        } else {
            setScNav(false)
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleNavScroll)
        return () => {
            window.removeEventListener('scroll', handleNavScroll)
        }
    })

    return (
        <>
        <Flex w="100%" backdropFilter={scNav ? "blur(12px)" : "none"} mb={[scNav ? "0px" : "-65px", scNav ? "0px" : "-80px"]} pt={[scNav ? "10px" : "20px", scNav ? "25px" : "30px"]} pb={[scNav ? "10px" : "0", scNav ? "25px" : "0"]} overflowX="hidden" bg={scNav ? "rgba(255,255,255,0.85)" : "transparent"} position={scNav ? "fixed" : "absolute"} top="0" zIndex="1000" boxShadow={scNav ? "0 1px 10px -4px rgba(0,0,0,.15)" : "none"} fontWeight={["600", "400"]} fontSize={["10px", "13px"]}>
            
            <Flex w="50%" pl={["5", "8%"]} align="center" data-aos="fade-right">
                {
                    ["Home", "About Us"].map((item, index) => (
                        <Flex display={location.pathname.includes("our-services") ? "none" : "flex"} key={index} justify="center" color={[scNav ? "#000" : location.pathname !== '/' ? "#fff" : "#000", scNav ? "#000" : location.pathname !== '/' && !location.pathname.includes("donations") ? "#fff" : "#000"]} bg={[scNav ? "rgba(25, 131, 212, .15)" : location.pathname !== '/' ? "rgba(255, 255, 255, .21)" : "rgba(25, 131, 212, .15)", scNav ? "rgba(25, 131, 212, .15)" : location.pathname !== '/' && !location.pathname.includes("donations") ? "rgba(255, 255, 255, .21)" : "rgba(25, 131, 212, .15)"]} align="center" px={["4", "7"]} h={["32px", "45px"]} mr={["3", "6"]}  borderRadius="30px" cursor="pointer" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} fontWeight="600" onClick={() => index === 0 ? history.push("/") : history.push("/about-us")}>
                            {item}
                        </Flex>
                    ))
                }
                {scNav && <Text ml="auto" fontWeight="600" display={["none", "block"]}>Love for</Text>}
            </Flex>




            <Flex w="50%" justify="flex-end" align="center" pr={["5", "8%"]} color={[scNav ? "#000" : location.pathname !== "/" ? "#fff" : "#000", scNav ? "#000" : "#fff"]} data-aos="fade-left">
                {scNav && <Text mr="auto" ml="1" display={["none", "block"]}>Health</Text>}
                <>
                <Flex fontSize={["18px", "20px"]} justify="center" align="center" px="5" w={["40px", "49px"]} h={["40px", "48px"]} mr={["2.5", "6"]} bg={[scNav ? "rgba(105, 105, 105, .08)" : location.pathname !== "/" ? "rgba(255, 255, 255, .21)" : "rgba(105, 105, 105, .08)", scNav ? "rgba(105, 105, 105, .08)" : "rgba(255, 255, 255, .21)"]} borderRadius="30px" cursor="pointer" transition="150ms ease-in-out" _hover={{ transform: "translate(0, -4px)" }} fontWeight="600" onClick={() => history.push(isLogged ? "/dashboard" : "/sign-in")}>
                    <Text><i className="mdi mdi-account"></i></Text>
                </Flex>
                </>

                <Flex fontSize={["18px", "20px"]} justify="center" align="center" px="5" w={["40px", "49px"]} h={["40px", "48px"]} mr={["2.5", "6"]} bg={[scNav ? "rgba(105, 105, 105, .08)" : location.pathname !== "/" ? "rgba(255, 255, 255, .21)" : "rgba(105, 105, 105, .08)", scNav ? "rgba(105, 105, 105, .08)" : "rgba(255, 255, 255, .21)"]} borderRadius="30px" cursor="pointer" transition="150ms ease-in-out" _hover={{ transform: "translate(0, -4px)" }} fontWeight="600" onClick={() => history.push("/our-services")}>
                    <Text><i className="mdi mdi-pulse"></i></Text>
                </Flex>

                <Flex display={["none", "flex"]} fontSize={["18px", "20px"]} justify="center" align="center" px="5" w={["40px", "49px"]} h={["40px", "48px"]} mr={["2.5", "6"]} bg={[scNav ? "rgba(105, 105, 105, .08)" : location.pathname !== "/" ? "rgba(255, 255, 255, .21)" : "rgba(105, 105, 105, .08)", scNav ? "rgba(105, 105, 105, .08)" : "rgba(255, 255, 255, .21)"]} borderRadius="30px" cursor="pointer" transition="150ms ease-in-out" _hover={{ transform: "translate(0, -4px)" }} fontWeight="600" onClick={() => history.push("/social")}>
                    <Text><i className="mdi mdi-twitter"></i></Text>
                </Flex>


                <Flex justify="center" align="center" w={["45px", "52px"]} h={["45px", "50px"]} fontSize={["20px", "25px"]} bg={[scNav ? "rgba(105, 105, 105, .08)" : location.pathname !== "/" ? "rgba(255, 255, 255, .21)" : "rgba(105, 105, 105, .08)", scNav ? "rgba(105, 105, 105, .08)" : "rgba(255, 255, 255, .21)"]} borderRadius="100%" cursor="pointer" transition="150ms ease-in-out" _hover={{ transform: "translate(0, -4px)" }} onClick={() => setMenu(true)}>
                    <i className="mdi mdi-menu"></i>
                </Flex>
            </Flex>
        </Flex>

        {
            menu && 
            <Flex w="100%" h="100%" position="fixed" top="0" left="0" zIndex="1000000" bg="rgba(0,0,0,0.7)" backdropFilter="blur(12px)" justify="flex-end">
                <Flex w={["80%", "600px"]} h="100%" bg="#fff" direction="column" px={["5", "12"]} py={["12", "6"]} data-aos="fade-left">
                    <Flex bg="#fff" ml="auto" border="2px solid #000" justify="center" align="center" color="#000" fontSize={["20px", "30px"]} cursor="pointer" borderRadius="100%" h={["40px", "50px"]} w={["41px", "51px"]} zIndex="10" onClick={() => setMenu(false)}><i className="mdi mdi-close"></i></Flex>
                    <Flex mt={["-12", "2"]} justify="center" flex="1" direction="column">
                        <Text fontSize={["25px", "40px"]}><Text fontWeight="700" as="span">Love For</Text> Health (LHO)</Text>
                        <Text mt="3" mb="4" fontSize="14px" color="rgb(102, 112, 133)">Quick Links</Text>
                        {
                            ["Sign In", "Register", "My Account", "Reach Out", "Donations", "Book Appointment", "Articles", "Tweets"].map((item, index) => (
                                <Flex key={index} _hover={{ zoom: "120%", color: "#0e8a3a", transform: "translate(0,0)" }} transition="300ms ease-in-out" cursor="pointer" fontWeight="500" fontSize={["17px", "19px"]} pt="4" pb="3" borderBottom="1px solid rgb(202, 202, 205)" display={isLogged && index === 0 ? "none" : isLogged && index === 1 ? "none" : !isLogged && index === 2 ? "none" : "flex"} onClick={() => {
                                    history.push(index === 0 ? "/sign-in" : index === 1 ? "/register" : index === 2 ? "/dashboard" : index === 3 ? "/contact-us" : index === 4 ? "/donations" : index === 5 ? "/booking" : index === 6 ? "/articles" : "/social")
                                    setMenu(false)
                                }}>{item}</Flex>
                            ))
                        }
                    </Flex>
                </Flex>
            </Flex>
        }
        </>
    )
}