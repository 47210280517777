import { Flex, Image, Text, Spinner, } from "@chakra-ui/react"
import { useEffect, useState, useRef } from "react"
import cover1 from "./cover1.jpg"
import logo from "./logo.png"
import cover2 from "./cover2.jpg"
import cover3 from "./cover3.jpg"
import cover4 from "./cover4.jpg"
import l1 from "./l1.jpg"
import icon1 from "./icon1.png"
import impact2 from "./r1.jpg"
import impact3 from "./imp3.jpg"
import impact4 from "./imp4.jpg"
import r2 from "./r2.jpg"
import globalImage from "./global.svg"
import { Accordion, AccordionItem as NewItem } from '@szhsin/react-accordion'
import axios from "axios"
import phone2 from "./phone2.png"
import d4 from "./d4.jpg"
import icon2 from "./icon2.png"
import { useHistory } from "react-router-dom"
import tw from "./twCover.jpg"
import twLogo from "./twLogo.png"
import hlt from "./hlt.jpg"
import { init } from 'ityped'
import hw11 from "./hw11.jpg"
import hw21 from "./hw21.jpg"
import hw22 from "./hw22.jpg"
import hw23 from "./hw23.jpg"
import hw31 from "./hw31.jpg"
import hw32 from "./hw32.jpg"
import hw33 from "./hw33.jpg"
import hw34 from "./hw34.jpg"
import f1 from "./vid/f1.mp4"
import f2 from "./vid/f2.mp4"
import f3 from "./vid/f3.mp4"
import f4 from "./vid/f4.mp4"
import f5 from "./vid/f5.mp4"
import f6 from "./vid/f6.mp4"
import u1 from "./u1.jpg"
import hs1 from "./hs1.jpeg"
import hs2 from "./hs2.jpeg"
import hs3 from "./hs3.jpeg"
import hs4 from "./hs4.jpeg"
import a1 from "./vid/a1.mp4"
import a2 from "./vid/a2.mp4"
import a4 from "./vid/a4.mp4"
import expert from "./expert.webp"
import { Helmet } from "react-helmet"
import f7 from "./vid/f7.mp4"

export default function Landing() {
    useEffect(() => {
        const scEleTwo = document.getElementById('scEleTwos')
        const scEleTwoWidth = scEleTwo.scrollWidth
        setInterval(() => {
            if (scEleTwo.scrollLeft < scEleTwoWidth) {
                scEleTwo.scrollTo(scEleTwo.scrollLeft + 1, 0)  
            }
        }, 15)
    }, [])
    const typeArea1 = useRef(null)

    useEffect(async () => {
        init(typeArea1.current, { showCursor: false, strings: ["1,238 Outreachs Attained", 'Including Children and Women',  'Free Medical Consultations' ] })
    }, [])

    const history = useHistory()

    
    const AccordionItem = ({ header, ...rest }) => (
        <NewItem
          {...rest}
          header={
            <Flex align="center" w="100%" mb={["-2", "0"]}>
              <Text className="font" fontSize={["16px", "18px"]} fontWeight="400">{header}</Text>
              <i className='mdi mdi-chevron-down chevron-down' style={{ fontSize: '25px' }}></i>
            </Flex>
          }
        />
    );

    const [newsData, setNewsData] = useState("")
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(0)
   
    useEffect(async () => {
        const url = 'https://api.nytimes.com/svc/search/v2/articlesearch.json?q=healthcare&fq=women&api-key=VvG8seu6yGiJJzm95XyvV9kjVwXtrecw&sort=newest&page='+page
        setLoading(true)
        const res = await axios.get(url)
        setNewsData(res?.data?.response?.docs)
        setLoading(false)
    }, [page])

    const [mod1, setMod1] = useState(false)
    const [modArr, setModArr] = useState("")



   
    return (
        <Flex w="100%" direction="column">
            <Helmet>
                <title>Love For Health</title>
            </Helmet>
            <Flex w="100%" minH="100vh" bg={["#000", "rgb(250,250,251)"]} overflowX="hidden" direction={["column", "row"]}>
                <Flex w={["100%", "50%"]} h={["58%", "100%"]} bg="rgb(250,250, 251)" py={["20px", "50px"]} pl={["5", "8%"]} pr={["5", "6%"]} direction="column">
                    <Flex flex="1" direction="column" justify="center" pt={["70px", "0"]}>
                        <Flex data-aos="fade-down" w={["71px", "101px"]} h={["70px", "100px"]} bgColor="rgba(100, 100, 100, .1)" mb={["3", "4"]} backgroundImage={logo} backgroundSize="65%" backgroundRepeat="no-repeat" borderRadius="100%" backgroundPosition="center center" className="swing">
                        </Flex>

                        <Text fontSize={["28px", "38px"]} data-aos="fade-up"><Text fontWeight="700" as="span">Love For </Text>Health <Text as="span" fontSize={["24px", "34px"]}>(LHO)</Text>.</Text>
                        <Text mt={["1.5", "2"]} data-aos="fade-up" transition="150ms ease-in-out" fontSize={["14px", "15px"]}><Text>“Caring for women”</Text> <Text>Free, accessible, and affordable healthcare services.</Text></Text>
                        
                        <Flex mt={["8", "10"]} align="center" data-aos="fade-up">
                            <Text w={["30px", "50px"]} h="2px" bg="#000" mr="2"></Text>
                            <Flex mr={["2", "4"]} border="2px solid #000" padding={["10px 25px", "16px 34px"]} cursor="pointer" borderRadius="30px" color="#000" fontWeight="600" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => history.push("/contact-us")}>Reach Out <Text ml="2">➜</Text></Flex>

                            <Flex border="2px solid #000" padding={["10px 28px", "16px 34px"]} cursor="pointer" borderRadius="30px" color="#000" fontWeight="600" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => history.push("/booking")}>Book <Text ml="2">➜</Text></Flex>
                        </Flex>
                    </Flex>
                </Flex>


                <Flex w={["100%", "50%"]} h={["42%", "100%"]} backgroundColor="#000" backgroundImage={cover1} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="center center" data-aos="fade-left">
                    <Flex w="100%" h="100%" overflow="hidden" bg="rgba(0,0,0,.35)" direction="column" px={["2", "3%"]} py={["0", "50px"]} color="#fff">
                        <Flex flex="1" direction="column" justify="center" mt={["0", "80px"]}>
                            <Flex w="100%" overflowX="scroll" className="noscbar" id="scEleTwos" borderRadius="8px">
                                <Flex flexWrap="nowrap" minW={["1030px", "1690px"]} align="center" mt={["0", "8"]} overflowX="scroll" className="noscbar">
                                    {
                                        [{name: "Free Clinical Services for Women", sub: "At Love for Health Organization (LHO), we provide free clinical services to address the healthcare struggles faced by women globally.", bg: cover2},{name: "Gender-Based Violence Support", sub: "We recognize the pervasive issue of gender-based violence globally and the significant impact it has on women's health and well-being.", bg: cover3},{name: "Advocacy and Education", sub: "We believe that education and advocacy are key to creating a society where women and the girl child can thrive", bg: cover4},].map((item, index) => (
                                            <Flex key={index} ml={[index === 0 ? "50px" : "10px", index === 0 ? "130px" : "30px"]} bg="rgba(255, 255, 255, .21)" borderRadius="8px" w={["320px", "500px"]} h={["280px", "500px"]} py={["6", "8"]} px={["6", "8"]} direction="column">
                                                <Flex justify="center" align="center" fontWeight="700" w="41px" h="40px" fontSize="20px" bg="rgba(255, 255, 255, .31)" borderRadius="100%">0{index+1}</Flex>
                                                <Flex mt="auto" w="100%" direction="column">
                                                    <Text mb="1" fontWeight="600">{item.name}</Text>
                                                    <Text mb="5" fontSize="13px">{item.sub}</Text>
                                                    <Flex w="100%" h="270px" display={["none", "flex"]} backgroundImage={item.bg} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="center center" borderRadius="8px" />
                                                </Flex>
                                            </Flex>
                                        ))
                                    }
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>

            <Flex w="100%" bg="#fff" px={["5", "15%"]} align="center" color="#000" py={["60px", "80px"]} direction="column">
                <Text data-aos="fade-down" fontSize={["26px", "40px"]}><Text fontWeight="700" as="span">Who</Text> We Are</Text>
                <Text mt="2" mb={["0", "8"]} fontSize={["12px", "14px"]} color="rgb(102, 112, 133)" textAlign="center" px={["0", "20%"]}>(LHO) a team of passionate health professionals dedicated to improving the health and well-being of women</Text>
                <Flex w="100%" data-aos="fade-up" mt={["7", "5"]}>
                    <video preload="metadata" controls muted autoPlay playsInline style={{ background: "#000", width: "100%", borderRadius: "10px"}} src={a1}></video>
                </Flex>
            </Flex>





            <Flex bg="rgb(250,250,252)" py={["60px", "80px"]} px={["5", "8%"]} direction={["column-reverse", "row"]} align={["flex-start", "center"]} justify="space-between">
                <Flex display={["none", "flex"]} w={["100%", "44%"]} data-aos="fade-up">
                    <Flex w="100%" backgroundImage={l1} h={["300px", "550px"]} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="center left" borderRadius={["12px 12px 0 0", "12px 0 0 12px"]}></Flex>
                </Flex>
                <Flex w={["100%", "50%"]} direction="column">
                    <Flex align="center" data-aos="fade-down">
                        <Image src={icon1} w={["30px", "50px"]} />
                        <Text ml="4" fontSize={["26px", "40px"]}><Text fontWeight="700" as="span">What </Text>We do</Text>
                    </Flex>
                    <Text bg="rgb(202, 202, 205)" h="1px" mt="4" mb="6"></Text>
                    <Flex mb="6" display={["flex", "none"]} w={["100%", "44%"]} data-aos="fade-up">
                        <Flex w="100%" borderRadius={["12px 12px 0 0", "12px 0 0 12px"]} backgroundImage={l1} h={["300px", "550px"]} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="center left"></Flex>
                    </Flex>
                    <Text fontSize="14px" color="rgb(102, 112, 133)" data-aos="fade-up">At LHO we provide a wide range of services to support women's health, including free clinical services, reproductive health services, prenatal care, and mental health services. We provide reliable and quality health information, management tips, and support to women in need. We also engage in advocacy and sensitization and channel concerted efforts to fight against gender-based violence, including rape and discrimination. We believe that every woman deserves access to quality healthcare and support, regardless of their financial status</Text>
                    <Flex w="100%" mt="10" justify="flex-end" align="center" data-aos="fade-up">
                        <Text w="50px" h="2px" bg="#000" mr="2"></Text><Flex bg="#fff" border="2px solid #000" padding={["10px 30px", "14px 32px"]} cursor="pointer" borderRadius="30px" color="#000" fontWeight="600" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => history.push("/about-us")}>About Us <Text ml="2">➜</Text></Flex>
                    </Flex>
                </Flex>
            </Flex>



            <Flex bg="rgb(255,245,245)" w="100%" px={["5", "15%"]} align="center" color="#000" py={["60px", "80px"]} direction="column">
                <Text data-aos="fade-down" fontSize={["26px", "40px"]}><Text fontWeight="700" as="span">#2023</Text> Recap</Text>
                <Text mt="2" mb={["0", "8"]} fontSize={["12px", "14px"]} color="rgb(102, 112, 133)" textAlign="center" px={["0", "20%"]}>Here is a recap of love for health in 2023, we took a huge step in providing care to the less privileged including orphanage homes. We renovated the gates and painted the walls. Finally we showed care by giving out foodstuffs.</Text>
                <Flex w="100%" data-aos="fade-up" mt={["7", "5"]} px={["0", "10%"]}>
                    <video preload="metadata" controls playsInline style={{ background: "#000", width: "100%", borderRadius: "10px", maxHeight: "400px" }} src={f7}></video>
                </Flex>
                <Flex w="100%" fontSize={["13px", "15px"]} mt={["8", "10"]} justify="center" align="center">
                    <Text w="50px" h="2px" bg="#000" mr="2"></Text><Flex bg="#fff" border="2px solid #000" padding={["10px 30px", "14px 32px"]} cursor="pointer" borderRadius="30px" color="#000" fontWeight="600" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => {
                        history.push("/donations")
                        setTimeout(function() {
                            const element = document.getElementById('sec2')
                            return window.scrollTo({
                                top: element ? element.offsetTop - 50 : 0,
                                behavior: 'smooth',
                            })
                        }, 100)
                    }}>Donate or Support <Text ml="2">➜</Text></Flex>
                </Flex>
            </Flex>


            <Flex h={["250px", "300px"]} justify="center" align="center" bg="#fff" w="100%" direction="column" px={["8", "8%"]}> 
                <Text data-aos="fade-down" fontSize={["26px", "40px"]}><Text fontWeight="700" as="span">Out</Text>reachs</Text>
                <Flex w="100%" h="100px" justify="center" align="center"><Text textAlign="center" ref={typeArea1} mt="8" fontSize={["30px", "40px"]} fontWeight="600"></Text></Flex>
            </Flex>

            <Flex pb="60px" pt={["40px", "80px"]} px={["5", "8%"]} justify="space-between" direction={["column", "row"]}>
                <Flex w={["100%", "40%" ]} borderRadius={["12px 12px 0 0", "12px 0 0 12px"]} boxShadow="0 1px 10px 4px rgba(0,0,0,.08)" backgroundColor="#000" backgroundImage={u1} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="center center" data-aos="fade-down" py={["100px", "0"]}></Flex>

                <Flex w={["100%", "58%"]} mt={["3", "0"]} borderRadius={["0 0 12px 12px", "0 12px 12px 0"]} direction="column" px={["6", "12"]} py={["10", "12" ]} boxShadow="0 1px 10px 4px rgba(0,0,0,.08)" data-aos="fade-up">
                    <Text data-aos="fade-down" fontSize={["26px", "40px"]}><Text fontWeight="700" as="span">Register</Text> Today!</Text>
                    <Text mt="3" mb={["0", "8"]} fontSize={["13px", "14px"]} color="rgb(102, 112, 133)" pr={["0", "20%"]}>You can register on Love For Health to have access to our amazing services for free - prenantal care counselling, mental health services, women health care education, advocacy and empowerment.</Text>
                    <Flex w="100%" mt="12" justify="flex-end" align="center" data-aos="fade-up">
                        <Text w="50px" h="2px" bg="#000" mr="2"></Text><Flex bg="#fff" border="2px solid #000" padding={["10px 30px", "14px 32px"]} cursor="pointer" borderRadius="30px" color="#000" fontWeight="600" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => history.push("/register")}>Get Started <Text ml="2">➜</Text></Flex>
                    </Flex>
                </Flex>
            </Flex>


            <Flex py={["60px", "80px"]} bg="rgb(250,250,251)" w="100%" align="center" direction="column" px={["0", "8%"]}> 
                <Text data-aos="fade-down" fontSize={["26px", "40px"]}><Text fontWeight="700" as="span">Our</Text> Impacts</Text>
                <Text mt="3" mb={["0", "8"]} fontSize={["13px", "14px"]} color="rgb(102, 112, 133)" px={["4", "20%"]} textAlign="center">We are making a difference in the lives of women in Nigeria, Africa and the world at large by attending to their health needs and promoting advocacy</Text>

                <Flex w="100%" direction="column" mt="12" px={["3", "0"]}>
                    <Flex w="100%" direction={["column", "row"]}>
                        <Flex w={["100%", "auto"]} fontSize={["60px", "80px"]} fontWeight="700" mt={["-4", "-10"]} data-aos="fade-down" px={["1", "0"]} justify={["center", "flex-start"]}>01</Flex>
                        
                        <Flex flex="1" direction="column" ml={["0", "12"]}>
                            <Text mb="8" fontSize={["14px", "16px"]} px={["2", "0"]} textAlign={["center", "left"]} fontWeight="500">Almighty Rolex secondary school, Lagos, Nigeria<Text fontSize={["12px", "14px"]} mt="1" color="rgb(102, 112, 133)" fontWeight="400">210 girls received souvenirs which includes menstrual pads, exercise books and other writing materials</Text></Text>
                            <Flex w="100%" justify="space-between" flexWrap="wrap" direction={["column", "row"]}>
                                {
                                    [hw11, hw21, hw23, hw22].map((item, index) => (
                                        <Flex w={["100%", "49%"]} key={index} backgroundColor="#000" backgroundImage={item} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="top center" h={["200px", "300px"]} mb={["3", "5"]} borderRadius="8px" data-aos="fade-up">      
                                            <Flex mt="auto" data-aos="fade-down" backdropFilter="blur(12px)" bg="rgba(255,255,255,.6)" direction="column" color="#000" w={["45px", "50px"]} h={["45px", "50px"]} justify="center" align="center" borderRadius="100%" ml="auto" mr="3" mb="4">
                                                <Text fontSize="17px" fontWeight="700">0{index + 1}</Text>
                                            </Flex>
                                        </Flex>
                                    ))
                                }
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>

                
                <Flex w="100%" direction="column" mt={["0", "80px"]} px={["3", "0"]} pt={["60px", "60px"]} borderTop={["none", "1px solid rgb(202, 202, 205)"]}>
                    <Flex w="100%" direction={["column", "row"]}>
                        <Flex w={["100%", "auto"]} fontSize={["60px", "80px"]} fontWeight="700" mt={["-4", "-10"]} data-aos="fade-down" px={["1", "0"]} justify={["center", "flex-start"]}>02</Flex>

                        <Flex flex="1" direction="column" ml={["0", "12"]}>
                            <Text mb="8" fontSize={["14px", "16px"]} px={["2", "0"]} textAlign={["center", "left"]} fontWeight="500">Unita Hospital and Maternity</Text>
                            <Flex w="100%" justify="space-between" flexWrap="wrap" direction={["column", "row"]}>
                                {
                                    [hs1, hs2, hs3, hs4].map((item, index) => (
                                        <Flex w={["100%", "49%"]} key={index} backgroundColor="#000" backgroundImage={item} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="bottom center" h={["200px", "300px"]} mb={["3", "5"]} borderRadius="8px" data-aos="fade-up">      
                                            <Flex mt="auto" data-aos="fade-down" backdropFilter="blur(12px)" bg="rgba(255,255,255,.6)" direction="column" color="#000" w={["45px", "50px"]} h={["45px", "50px"]} justify="center" align="center" borderRadius="100%" ml="auto" mr="3" mb="4">
                                                <Text fontSize="17px" fontWeight="700">0{index + 1}</Text>
                                            </Flex>
                                        </Flex>
                                    ))
                                }
                            </Flex>
                        </Flex>     
                    </Flex>
                </Flex>

                <Flex w="100%" direction="column" mt={["0", "80px"]} px={["3", "0"]} pb="50px" pt={["60px", "60px"]} borderTop={["none", "1px solid rgb(202, 202, 205)"]} borderBottom={["none", "1px solid rgb(202, 202, 205)"]}>
                    <Flex w="100%" direction={["column", "row"]}>
                        <Flex w={["100%", "auto"]} fontSize={["60px", "80px"]} fontWeight="700" mt={["-4", "-10"]} data-aos="fade-down" px={["1", "0"]} justify={["center", "flex-start"]}>03</Flex>

                        <Flex flex="1" direction="column" ml={["0", "12"]}>
                            <Text mb="8" fontSize={["14px", "16px"]} px={["2", "0"]} textAlign={["center", "left"]} fontWeight="500">Pivic group of schools, Lagos, Nigeria<Text fontSize={["12px", "14px"]} mt="1" color="rgb(102, 112, 133)" fontWeight="400">87 girls were given gifts and souvenirs</Text></Text>
                            <Flex w="100%" justify="space-between" flexWrap="wrap" direction={["column", "row"]}>
                                {
                                    [hw31, hw32, hw33, hw34].map((item, index) => (
                                        <Flex w={["100%", "49%"]} key={index} backgroundColor="#000" backgroundImage={item} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="top center" h={["200px", "300px"]} mb={["3", "5"]} borderRadius="8px" data-aos="fade-up">      
                                            <Flex mt="auto" data-aos="fade-down" backdropFilter="blur(12px)" bg="rgba(255,255,255,.6)" direction="column" color="#000" w={["45px", "50px"]} h={["45px", "50px"]} justify="center" align="center" borderRadius="100%" ml="auto" mr="3" mb="4">
                                                <Text fontSize="17px" fontWeight="700">0{index + 1}</Text>
                                            </Flex>
                                        </Flex>
                                    ))
                                }
                            </Flex>
                        </Flex>     
                    </Flex>
                </Flex>
                

                <Flex w="100%" mt={["0", "60px"]} justify="space-between" direction={["column-reverse", "row"]}>
                    <Flex w={["100%", "53%"]} flexWrap="wrap">
                        {
                            [{ini: "Caring for women", name: "free clinical services to thousands of women", bg: impact2}, {ini: "Empowering", name: "survivors of gender-based violence.", bg: impact3}, {ini: "Awareness", name: "raising awareness about women's health issues", bg: impact4}].map((item, index) => (
                                <Flex data-aos={index === 0 ? "fade-right" : index === 1 ? "fade-down" : "fade-up"} mb={["0", index === 0 && "30px"]} mr={["0", index === 1 && "4%"]} key={index} w={["100%", index === 0 ? "100%" : "48%"]} px="5" h={[index === 0 ? "300px" : "270px", "400px"]} py="5" backgroundColor="#000" backgroundImage={item.bg} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="center center">
                                    <Flex mt="auto" backdropFilter="blur(12px)" w="100%" bg="rgba(255,255,255,.6)" py="4" px="4" direction="column" color="#000">
                                        <Text fontSize="17px" fontWeight="600">0{index + 1}. {item.ini}</Text>
                                        <Text fontSize="13px">{item.name}</Text>
                                    </Flex>
                                </Flex>
                            ))
                        }
                    </Flex>

                    <Flex w={["100%", "45%"]} data-aos="fade-up" backgroundImage={r2} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="center center">
                        <Flex w="100%" py={["12", "8"]} bg="rgba(0,0,0,0.6)" px="8" direction="column" justify="center" color="#fff">
                            <Text fontWeight="700" fontSize={["40px", "50px"]}>LHO Goals<Text fontSize={["20px", "30px"]}>(100k Outreachs/Year)</Text></Text>
                            <Text mt="3" fontSize="14px">We have the goal of delivering 100,000 outreachs in a year and 1 Million outreachs in 10 years through our dedicated team consisting of experienced health professionals, including doctors, nurses, counsellors, and advocates. Together, we bring a wealth of knowledge and expertise to address the healthcare struggles faced by women in globally</Text>
                            <Flex mt="12" align="center" justify="flex-end">
                                <Text w="50px" h="2px" bg="#fff" mr="2"></Text>
                                <Flex border="2px solid #fff" padding={["10px 30px", "10px 20px"]} cursor="pointer" borderRadius="30px" color="#fff" fontWeight="700" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => history.push("/our-services")}>Our Services<Text ml="2">➜</Text></Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>


            

            
            <Flex py={["60px", "80px"]} bg="#fff" w="100%" align="center" direction="column" px={["5", "8%"]}>
                <Text data-aos="fade-down" fontSize={["26px", "40px"]}><Text fontWeight="700" as="span">Fea</Text>Tured</Text>
                <Text mt="2" mb={["0", "8"]} fontSize={["12px", "14px"]} color="rgb(102, 112, 133)" textAlign="center" px={["0", "20%"]}>(LHO) a team of passionate health professionals dedicated to improving the health and well-being of women</Text>
                
                <Flex w="100%" px={["0", "10%"]} direction="column" mt="8">
                    <Flex w="100%" borderRadius="12px" px={["5", "12"]} py={["10", "12" ]} boxShadow="0 1px 10px 4px rgba(0,0,0,.08)" data-aos="fade-up" align="flex-start" direction={["column", "row"]}>
                        <Flex backgroundImage={expert} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="center center" w={["80px", "80px" ]} h={["80px", "80px"]} borderRadius="100%" />
                        <Flex flex="1" ml={["0", "7"]} direction="column" mt={["5", "0"]}>
                            <Text fontWeight="700" fontSize={["18px", "20px"]}>Why FG must fund Biomedical Research in Nigeria—Medical Expert</Text>
                            <Text mt="2" fontWeight="600" color="slategray" fontSize="16px">Via: <Text as="span" color="red">Vanguard</Text></Text>
                            <Text mt="4" fontSize={["13px", "14px"]}>“The Nigerian Government needs to do more on biomedical research, we need to promote and train young minds, promote innovations and academic research to be prepared for expected and unexpected eventualities and contingencies like global pandemics.”</Text>

                            <Flex w="100%" fontSize={["13px", "15px"]} mt={["8", "10"]} justify="flex-end" align="center" data-aos="fade-up">
                                <Text w="50px" h="2px" bg="#000" mr="2"></Text><Flex bg="#fff" border="2px solid #000" padding={["10px 30px", "14px 32px"]} cursor="pointer" borderRadius="30px" color="#000" fontWeight="600" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => window.open("https://www.vanguardngr.com/2023/09/why-fg-must-fund-biomedical-research-in-nigeria-medical-expert/", "_BLANK")}>Read on Vanguard <Text ml="2">➜</Text></Flex>
                            </Flex>
                        </Flex> 
                    </Flex>


                    <Flex w="100%" mt="5" borderRadius="12px" px={["5", "12"]} py={["10", "12" ]} boxShadow="0 1px 10px 4px rgba(0,0,0,.08)" data-aos="fade-up" align="flex-start" direction={["column", "row"]}>
                        <Flex backgroundImage={expert} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="center center" w={["80px", "80px" ]} h={["80px", "80px"]} borderRadius="100%" />
                        <Flex flex="1" ml={["0", "7"]} direction="column" mt={["5", "0"]}>
                            <Text fontWeight="700" fontSize={["18px", "20px"]}>Leadership empowerment aids creativity, says medical expert</Text>
                            <Text mt="2" fontWeight="600" color="slategray" fontSize="16px">Via: <Text as="span" color="red">Punch</Text></Text>
                            <Text mt="4" fontSize={["13px", "14px"]}>Creative engagement helps employees build essential skills, which include identifying a problem, searching and encoding information, and generating alternatives and essential ideas.</Text>

                            <Flex w="100%" fontSize={["13px", "15px"]} mt={["8", "10"]} justify="flex-end" align="center" data-aos="fade-up">
                                <Text w="50px" h="2px" bg="#000" mr="2"></Text><Flex bg="#fff" border="2px solid #000" padding={["10px 30px", "14px 32px"]} cursor="pointer" borderRadius="30px" color="#000" fontWeight="600" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => window.open("https://punchng.com/leadership-empowerment-aids-creativity-says-medical-expert/", "_BLANK")}>Read on Punch <Text ml="2">➜</Text></Flex>
                            </Flex>
                        </Flex> 
                    </Flex>
                </Flex>
            </Flex>
            



            <Flex py={["80px", "60px"]} bgColor="rgba(35, 166, 35, .25)" backgroundImage={globalImage} backgroundRepeat="no-repeat" backgroundSize={["120%", "40%"]} backgroundPosition="bottom right" align="center" px={["5", "20%"]} justify="space-between">
                <Flex direction="column" align="center" data-aos="fade-down"><Text fontSize={["30px", "80px"]} fontWeight="700">1,238</Text><Text fontSize={["12px", "15px"]} textAlign="center">Outreachs Attained</Text></Flex>
                <Flex direction="column" align="center" data-aos="fade-up"><Text fontSize={["30px", "80px"]}fontWeight="700">100K</Text><Text fontSize={["12px", "15px"]} textAlign="center">Goal: Outreach Yearly</Text></Flex>
                <Flex direction="column" align="center" data-aos="fade-down"><Text fontSize={["30px", "80px"]}fontWeight="700">1M</Text><Text fontSize={["12px", "15px"]} textAlign="center">Goal: Outreach In 10 Years</Text></Flex>
            </Flex>

            <Flex w="100%" bg="rgb(250,250,252)" px={["5", "20%"]} align="center" color="#000" py={["60px", "100px"]} direction="column">
                <Text data-aos="fade-down" fontSize={["26px", "40px"]}><Text fontWeight="700" as="span">Our</Text> Outreachs</Text>
                <Text mt="2" mb="8" fontSize={["13px", "14px"]} color="rgb(102, 112, 133)" textAlign="center">Clip from our recent outreach at Unita Hospital and Maternity</Text>
                <Flex w="100%" data-aos="fade-up">
                    <video preload="metadata" controls playsInline style={{ background: "#000", width: "100%", borderRadius: "10px", maxHeight: "500px"}} src={a4}></video>
                </Flex>

                <Text mt="100px" mb={["0", "8"]} fontSize={["13px", "14px"]} color="rgb(102, 112, 133)" textAlign="center">Clip from our recent outreach at Almighty Rolex secondary school, Lagos, Nigeria.</Text>
                <Flex w="100%" data-aos="fade-up" mt={["8", "0"]}>
                    <video preload="metadata" controls playsInline style={{ background: "#000", width: "100%", borderRadius: "10px"}} src={f4}></video>
                </Flex>
            </Flex>


            <Flex w="100%" bg="#fff" px={["5", "15%"]} align="center" color="#000" py={["60px", "80px"]} direction="column" borderBottom={["none", "1px solid rgb(202, 202, 205)"]}>
                <Text data-aos="fade-down" fontSize={["26px", "40px"]}><Text fontWeight="700" as="span">Love For </Text>Health</Text>
                <Text mt="2" mb={["0", "8"]} fontSize={["12px", "14px"]} color="rgb(102, 112, 133)" textAlign="center" px={["0", "20%"]}>(LHO) - Not everyone with HIV contacted it through sex. Hepatitis is more infectious.</Text>
                <Flex w="100%" data-aos="fade-up" mt={["7", "5"]}>
                    <video preload="metadata" controls playsInline style={{ background: "#000", width: "100%", borderRadius: "10px"}} src={a2}></video>
                </Flex>
            </Flex>


            <Flex w="100%" bg="#fff" px={["5", "8%"]} align="center" color="#000" pt={["20px", "80px"]} pb={["60px", "100px"]} direction={["column", "row"]}>
                <Flex w="48%" data-aos="fade-up" display={["none", "flex"]}>
                    <video preload="metadata" controls playsInline style={{ background: "#000", width: "100%", borderRadius: "10px", maxHeight: "500px" }} src={f6}></video>
                </Flex>
                <Flex w={["100%", "52%"]} direction="column" pl={["0", "12"]}>
                    <Text mt="2" color="rgb(102, 112, 133)" fontSize={["17px", "24px"]} fontWeight="500" data-aos="fade-up">Women Support</Text>
                    <Text mt="2" color="rgb(102, 112, 133)" data-aos="fade-up">By Omotara</Text>
                    <Text bg="rgb(202, 202, 205)" h="1px" data-aos="fade-up" mt="4" mb="6"></Text>

                    <Flex w="100%" px="0%" mb="8" data-aos="fade-up" display={["flex", "none"]}>
                        <video preload="metadata" controls playsInline style={{ background: "#000", width: "100%", borderRadius: "10px", maxHeight: "400px" }} src={f6}></video>
                    </Flex>
                    
                    <Text fontSize={["14px", "17px"]} data-aos="fade-up" color="rgb(102, 112, 133)">In our society today women go through a lot of challenges, that is why LHO cares and supports women in dealing with both mental and physical health problems.</Text>
                    <Flex w="100%" mt="10" justify="flex-end" align="center" data-aos="fade-up">
                        <Text w="50px" h="2px" bg="#000" mr="2"></Text><Flex bg="#fff" border="2px solid #000" padding={["10px 30px", "14px 32px"]} cursor="pointer" borderRadius="30px" color="#000" fontWeight="600" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => history.push("/contact-us")}>Reach Out <Text ml="2">➜</Text></Flex>
                    </Flex>
                </Flex>
            </Flex>


            <Flex w="100%" bg="#fff" px={["5", "8%"]} align="center" color="#000" pb={["60px", "100px"]} direction={["column", "row"]}>
                <Flex w="48%" data-aos="fade-up" display={["none", "flex"]}>
                    <video preload="metadata" loop controls playsInline style={{ background: "#000", width: "100%", borderRadius: "10px", maxHeight: "500px"}} src={f3}></video>
                </Flex>
                <Flex w={["100%", "52%"]} direction="column" pl={["0", "12"]}>
                    <Image src={icon2} data-aos="fade-down" w="50px" mb="3" />
                    <Text data-aos="fade-down" fontSize={["26px", "60px"]} fontWeight="700">LHO & <Text fontWeight="300" as="span">DidiFit</Text></Text>
                    <Text mt="2" color="rgb(102, 112, 133)" fontSize={["17px", "24px"]} fontWeight="500" data-aos="fade-up">Partnership with DidiFit</Text>
                    <Text bg="rgb(202, 202, 205)" h="1px" data-aos="fade-up" mt="4" mb="6"></Text>

                    <Flex w="100%" px="0%" mb="8" data-aos="fade-up" display={["flex", "none"]}>
                        <video preload="metadata" loop controls playsInline style={{ background: "#000", width: "100%", borderRadius: "10px", maxHeight: "400px" }} src={f3}></video>
                    </Flex>
                    
                    <Text fontSize={["14px", "17px"]} data-aos="fade-up" color="rgb(102, 112, 133)">Love for health services are 100% free and 100% virtual, prenantal care counselling, mental health services, women health care education, advocacy and empowerment..</Text>
                    <Flex w="100%" mt="10" justify="flex-end" align="center" data-aos="fade-up">
                        <Text w="50px" h="2px" bg="#000" mr="2"></Text><Flex bg="#fff" border="2px solid #000" padding={["10px 30px", "14px 32px"]} cursor="pointer" borderRadius="30px" color="#000" fontWeight="600" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => history.push("/contact-us")}>Reach Out <Text ml="2">➜</Text></Flex>
                    </Flex>
                </Flex>
            </Flex>

            
            <Flex w="100%" bg="#fff" px={["5", "8%"]} align="center" color="#000" pb={["60px", "100px"]} direction={["column", "row"]}>
                <Flex w="48%" data-aos="fade-up" display={["none", "flex"]}>
                    <video preload="metadata" controls playsInline style={{ background: "#000", width: "100%", borderRadius: "10px", maxHeight: "500px" }} src={f1}></video>
                </Flex>
                <Flex w={["100%", "52%"]} direction="column" pl={["0", "12"]}>
                    <Text mt="2" color="rgb(102, 112, 133)" fontSize={["17px", "24px"]} fontWeight="500" data-aos="fade-up">Breast Cancer Awareness</Text>
                    <Text mt="2" color="rgb(102, 112, 133)" data-aos="fade-up">By Ada Anambra</Text>
                    <Text bg="rgb(202, 202, 205)" h="1px" data-aos="fade-up" mt="4" mb="6"></Text>

                    <Flex w="100%" px="0%" mb="8" data-aos="fade-up" display={["flex", "none"]}>
                        <video preload="metadata" controls playsInline style={{ background: "#000", width: "100%", borderRadius: "10px", maxHeight: "400px" }} src={f1}></video>
                    </Flex>
                    
                    <Text fontSize={["14px", "17px"]} data-aos="fade-up" color="rgb(102, 112, 133)">Every woman has 98% chance of beating breast cancer if being detected at an early stage, always examine your breast and quickly speak to a Doctor after any little sign of discomfort.</Text>
                    <Flex w="100%" mt="10" justify="flex-end" align="center" data-aos="fade-up">
                        <Text w="50px" h="2px" bg="#000" mr="2"></Text><Flex bg="#fff" border="2px solid #000" padding={["10px 30px", "14px 32px"]} cursor="pointer" borderRadius="30px" color="#000" fontWeight="600" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => history.push("/contact-us")}>Reach Out <Text ml="2">➜</Text></Flex>
                    </Flex>
                </Flex>
            </Flex>



            
            <Flex py={["60px", "80px"]} bg="rgb(250,250,252)" w="100%" align="center" direction="column" px={["5", "8%"]}> 
                <Text data-aos="fade-down" fontSize={["26px", "40px"]}><Text fontWeight="700" as="span">The Need</Text> and FAQ's</Text>
                <Text mt="3" data-aos="fade-up" fontSize={["13px", "14px"]} color="rgb(102, 112, 133)" px={["2", "20%"]} textAlign="center">Women in Africa face significant challenges in accessing quality healthcare due to limited healthcare facilities and skilled healthcare professionals, especially in rural areas</Text>

                <Flex mt={["0", "6"]} direction="column" w="100%" px={["5", "15%"]} data-aos="fade-up">
                    <Accordion transition transitionTimeout={200}>
                        <AccordionItem header="Expand our reach">
                            With your support, we can extend our free clinical services to more women in need, particularly in underserved rural areas where access to healthcare is limited
                        </AccordionItem>

                        <AccordionItem header="Enhance support for survivors of gender-based violence">
                            We are able to provide comprehensive support services, including counselling, medical care, and legal aid, to more survivors of gender-based violenc
                        </AccordionItem>

                        <AccordionItem header="Scale up advocacy effort">
                            With increased resources, we can amplify our advocacy initiatives and create lasting change by promoting policies and programs that prioritize women's health and rights
                        </AccordionItem>
                        <AccordionItem header="Is our service free?">
                            Yes
                        </AccordionItem>
                        <AccordionItem header="What services do we offer">
                            Free Clinical Services for Women, Gender-Based Violence Support, Advocacy and Education.
                        </AccordionItem>
                    </Accordion>
                </Flex>
            </Flex>




            <Flex py={["60px", "80px"]} bg="rgb(244,244,246)" w="100%" align="center" direction="column" px={["5", "8%"]}> 
                <Text data-aos="fade-down" fontSize={["26px", "40px"]}><Text fontWeight="700" as="span">LHO Health</Text> Daily</Text>
                <Text mt="3" data-aos="fade-up" fontSize={["13px", "14px"]} color="rgb(102, 112, 133)" px={["2", "20%"]} textAlign="center" id="are2"> Daily health related articles from over a thousand medical authors.</Text>

                <Flex direction="column" w="100%" mt={["6", "8"]}>
                    {!loading && <Flex w="100%" mb={["10", "10"]} justify="center" align="center" data-aos="fade-up">
                        <Text ml="auto"></Text>
                        <Text w={["30px", "50px"]} h="2px" bg="#000" mr="2"></Text>
                        <Flex border="2px solid #000" padding={["11px 21px", "18px 35px"]} fontSize={["13px", "15px"]} cursor="pointer" borderRadius="30px" color="#000" fontWeight="600" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => history.push("/social")}>Our Tweets<Text ml="2">➜</Text></Flex>
                        <Flex ml={["2", "4"]} fontSize={["13px", "15px"]} border="2px solid #000" padding={["11px 21px", "18px 35px"]} cursor="pointer" borderRadius="30px" color="#000" fontWeight="600" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => {
                            history.push("/articles")
                        }}>Articles<Text ml="2">➜</Text></Flex><Text display={["none", "block"]} w={["30px", "50px"]} h="2px" bg="#000" ml="2"></Text>
                    </Flex>}
                    {
                        loading ? 
                        <Flex justify="center"  py="12"><Spinner color="#000" emptyColor="lightgrey" size="xl" /></Flex> :
                        Array.isArray(newsData) &&
                        newsData.map((item, index) => (
                            <Flex direction={["column", "row"]} key={index} data-aos="fade-up" w="100%" boxShadow="0px 0px 10px rgba(0,0,0,0.2)" mb="7" display={index > 1 ? "none" : "flex"}>
                                <Flex bgColor="#000" w={["100%", "40%"]} h={["200px", "auto"]} backgroundImage={item?.multimedia[0]?.url ? "https://www.nytimes.com/"+item?.multimedia[0]?.url : hlt} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="center center"></Flex>

                                <Flex flex="1" py={["5", "8"]} px={["5", "8"]} direction="column" bg="#fff">
                                    <Text fontSize={["17px", "22px"]} fontWeight="700" cursor="pointer" _hover={{ textDecoration: "underline" }} onClick={() => {
                                        setModArr(item)
                                        setMod1(true)
                                    }}>{item?.headline?.main}</Text>
                                    <Text mt={["3", "4"]} color="rgb(102, 112, 133)" fontSize={["15px", "18px"]}>{item?.snippet?.substr(0,200)}....</Text>
                                    <Flex w="100%" color="rgb(102, 112, 133)" mt="7" fontSize={["10px", "15px"]} justify={["space-between", "flex-start"]}>
                                        <Flex align="center"><Text fontSize={["20px", "25px"]}><i className="mdi mdi-calendar-clock"></i></Text> <Text ml="2">{item?.pub_date?.substr(0,10)}, {item?.pub_date?.substr(11,5)}</Text></Flex>
                                        <Flex ml="8" align="center"><Text fontSize={["20px", "25px"]}><i className="mdi mdi-account-outline"></i></Text> <Text ml="2" textTransform="capitalize" wordBreak="break-word">{item?.byline?.original ? item?.byline?.original?.toLowerCase() : "Author 0"+index}</Text></Flex>
                                    </Flex>
                                </Flex>
                            </Flex>
                        ))
                    }
                </Flex>
                {!loading && <Flex w="100%" mt={["6", "10"]} justify="center" align="center" data-aos="fade-up">
                    <Text w="50px" h="2px" bg="#000" mr="2"></Text><Flex bg="#fff" border="2px solid #000" padding={["15px 30px", "18px 35px"]} cursor="pointer" borderRadius="30px" color="#000" fontWeight="600" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => {
                        history.push("/articles")
                    }}>More Articles<Text ml="2">➜</Text></Flex>
                </Flex>}

                {
                    mod1 &&
                    <Flex w="100%" h="100%" position="fixed" top="0" left="0" zIndex="100000" bg="rgba(0,0,0,0.5)" backdropFilter="blur(12px)" justify="center" align="center">
                        <Flex w={["90%", "800px"]} data-aos="fade-up" bg="#fff" direction="column">
                            <Flex bgColor="#000" w="100%" h="200px" backgroundImage={modArr?.multimedia[0]?.url ? "https://www.nytimes.com/"+modArr?.multimedia[0]?.url : hlt} backgroundSize="cover" backgroundRepeat="no-repeat" justify="flex-end" pt="4" pr="4" backgroundPosition="center center">
                                <Flex bg="#fff" justify="center" align="center" color="#000" fontSize={["20px", "30px"]} cursor="pointer" borderRadius="100%" h={["40px", "50px"]} w={["41px", "51px"]} onClick={() => setMod1(false)}><i className="mdi mdi-close"></i></Flex>
                            </Flex>

                            <Flex flex="1" mt="6" p={["8", "12"]} maxH={["60vh", "50vh"]} overflowY="scroll" direction="column" bg="#fff">
                                <Text fontSize={["17px", "22px"]} fontWeight="700" cursor="pointer">{modArr?.headline?.main}</Text>
                                <Text mt={["3", "4"]} color="rgb(102, 112, 133)" fontSize={["15px", "18px"]}>{modArr?.lead_paragraph}</Text>
                                <Flex w="100%" color="rgb(102, 112, 133)" mt="7" fontSize={["10px", "15px"]} justify={["space-between", "flex-start"]}>
                                    <Flex align="center"><Text fontSize={["20px", "25px"]}><i className="mdi mdi-calendar-clock"></i></Text> <Text ml="2">{modArr?.pub_date?.substr(0,10)} @{modArr?.pub_date?.substr(11,5)}</Text></Flex>
                                    <Flex ml="8" align="center"><Text fontSize={["20px", "25px"]}><i className="mdi mdi-account-outline"></i></Text> <Text ml="2" textTransform="capitalize" wordBreak="break-word">{modArr?.byline?.original ? modArr?.byline?.original?.toLowerCase() : "Author 00"}</Text></Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                }
            </Flex>

            <Flex w="100%" bg="#fff" px={["5", "20%"]} align="center" color="#000" py={["60px", "100px"]} direction="column">
                <Text data-aos="fade-down" fontSize={["26px", "40px"]}><Text fontWeight="700" as="span">Our</Text> Outreachs</Text>
                <Text mt="3" mb={["0", "8"]} fontSize={["13px", "14px"]} color="rgb(102, 112, 133)" textAlign="center">Clip from our recent outreach at Pivic group of schools, Lagos, Nigeria</Text>
                <Flex w="100%" data-aos="fade-up" mt="8">
                    <video preload="metadata" controls playsInline style={{ background: "#000", width: "100%", borderRadius: "10px"}} src={f5}></video>
                </Flex>
            </Flex>

            <Flex w="100%" h={["50vh", "65vh"]} backgroundImage={tw} backgroundRepeat="no-repeat" backgroundSize="cover" backgroundPosition="center center">
                <Flex w="100%" bg="rgba(0,0,0,0.4)" justify="center" backdropFilter="blur(4px)" color="#fff" py="60px" px={["5", "8%"]} h="100%" direction="column" align="center">
                    <Flex data-aos="fade-down"><Image w={["60px", "80px"]} mb="8" src={twLogo} /></Flex>
                    <Text textAlign="center" data-aos="fade-down" fontSize={["27px", "40px"]} fontWeight="700">LHO Daily <Text fontWeight="300" as="span">Tweets</Text></Text>
                    <Text fontSize={["14px", "17px"]} data-aos="fade-up" textAlign="center" px={["5", "18%"]}>
                        Our daily conversations on our twitter handle and several engagements.
                    </Text>
                    <Flex w="100%" mt={["6", "10"]} justify="center" align="center" data-aos="fade-up">
                    <Text w={["30px", "50px"]} h="2px" bg="#fff" mr="2"></Text><Flex border="2px solid #fff" padding={["14px 30px", "18px 35px"]} cursor="pointer" borderRadius="30px" color="#fff" fontWeight="600" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => history.push("/social")}>Join Conversation<Text ml="2">➜</Text></Flex><Text w={["30px", "50px"]} h="2px" bg="#fff" ml="2"></Text></Flex>
                </Flex>
            </Flex>

            <Flex w="100%" bg="#fff" px={["5", "8%"]} align="center" color="#000" py={["60px", "100px"]} direction={["column", "row"]}>
                <Flex w="48%" data-aos="fade-up" display={["none", "flex"]}>
                    <video preload="metadata" controls playsInline style={{ background: "#000", width: "100%", borderRadius: "10px", objectFit: "cover"}} src={f2}></video>
                </Flex>
                <Flex w={["100%", "52%"]} direction="column" pl={["0", "12"]}>
                    <Image src={icon2} data-aos="fade-down" w="50px" mb="3" />
                    <Text data-aos="fade-down" fontSize={["26px", "60px"]} fontWeight="700">Featured <Text fontWeight="300" as="span">Weekly</Text></Text>
                    <Text mt="2" color="rgb(102, 112, 133)" fontSize={["17px", "24px"]} fontWeight="500" data-aos="fade-up">Teenage Pregnancy and Sexual Abuse</Text>
                    <Text bg="rgb(202, 202, 205)" h="1px" data-aos="fade-up" mt="4" mb="6"></Text>

                    <Flex w="100%" px="0%" mb="8" data-aos="fade-up" display={["flex", "none"]}>
                        <video preload="metadata" controls playsInline style={{ background: "#000", width: "100%", borderRadius: "10px", objectFit: "cover"}} src={f2}></video>
                    </Flex>
                    
                    <Text fontSize={["14px", "17px"]} data-aos="fade-up" color="rgb(102, 112, 133)">Pregnancy and child birth are the major cause of death in teenage girls. Above 400 unplanned deaths happen in Nigeria alone and half of this numbers are teenage girls....</Text>
                    <Flex w="100%" mt="10" justify="flex-end" align="center" data-aos="fade-up">
                        <Text w="50px" h="2px" bg="#000" mr="2"></Text><Flex bg="#fff" border="2px solid #000" padding={["10px 30px", "14px 32px"]} cursor="pointer" borderRadius="30px" color="#000" fontWeight="600" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => history.push("/contact-us")}>Reach Out <Text ml="2">➜</Text></Flex>
                    </Flex>
                </Flex>
            </Flex>




            

            <Flex py={["60px", "80px"]} bg="rgb(250,250,252)" w="100%" align="center" direction="column" px={["5", "8%"]}> 
                <Text data-aos="fade-down" fontSize={["20px", "40px"]}><Text fontWeight="700" as="span">In Need Of</Text> Health Service?</Text>
               

                <Flex w="100%" mt="8" align="center" direction={["column-reverse", "row"]}>
                    <Flex w={["100%", "55%"]} direction="column" pl={["3", "5%"]} pr={["3", "10%"]}>   
                        <Text data-aos="fade-down" fontSize={["26px", "40px"]}><Text fontWeight="700" as="span">Reach </Text> Out</Text>
                        <Text mt="3" data-aos="fade-up" fontSize="14px" color="rgb(102, 112, 133)">Together, we bring a wealth of knowledge and expertise to address the healthcare struggles faced by women globally. Our team is committed to providing compassionate care, support, and advocacy for women's health and rights all for free.</Text>
                        <Flex w="100%" mt="10" justify="flex-end" align="center" data-aos="fade-up">
                            <Text w="50px" h="2px" bg="#000" mr="2"></Text><Flex bg="#fff" border="2px solid #000" padding={["10px 30px", "14px 32px"]} cursor="pointer" borderRadius="30px" color="#000" fontWeight="600" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => history.push("/booking")}>Get a Doctor <Text ml="2">➜</Text></Flex>
                        </Flex>
                    </Flex>
                    <Flex mb={["8", "0"]} w={["100%", "45%"]} px={["10%", "0"]} data-aos="fade-down">
                        <Image w="100%" src={phone2} filter="drop-shadow(0 0px 10px rgba(0,0,0,.55))" />
                    </Flex>
                </Flex>
            </Flex>

            

            <Flex py={["60px", "80px"]} bg="#fff" w="100%" align="center" direction="column" px={["5", "8%"]}> 
                <Flex w="100%" align="center" direction={["column", "row"]}>
                    <Flex w={["100%", "45%"]} h={["300px", "400px"]} borderRadius="8px" data-aos="fade-down" backgroundImage={d4} backgroundRepeat="no-repeat" backgroundSize="cover" backgroundPosition="center center">
                    </Flex>
                    
                    <Flex mt={["8", "0"]} w={["100%", "55%"]} direction="column" px={["0", "5%"]}>   
                        <Text data-aos="fade-down" fontSize={["26px", "40px"]}><Text fontWeight="700" as="span">Join Us</Text> or Support</Text>
                        <Text bg="rgb(202, 202, 205)" h="1px" mt="4" mb="6"></Text>
                        <Text data-aos="fade-up" fontSize="14px" color="rgb(102, 112, 133)">We can't do it alone! Your contribution will help us reduce maternal mortality rates, improve access to healthcare, support survivors of gender-based violence, and empower women through education and advocacy. We will provide regular updates on our programs and outcomes, and ensure transparent reporting on how your support is making a difference.</Text>
                        <Flex w="100%" mt="10" justify="flex-end" align="center" data-aos="fade-up">
                            <Text w="50px" h="2px" bg="#000" mr="2"></Text><Flex bg="#fff" border="2px solid #000" padding={["10px 30px", "14px 32px"]} cursor="pointer" borderRadius="30px" color="#000" fontWeight="600" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => history.push("/donations")}>See our proposal <Text ml="2">➜</Text></Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    )
}