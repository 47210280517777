import { Flex, Image, Text, Spinner, } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import logo from "./logo.png"
import twCover from "./tw.jpg"
import { Helmet } from "react-helmet"


export default function TweetsArticles() {
    const [loading2, setLoading2] = useState(true)

    useEffect(() => {
        setTimeout(function() {
            setLoading2(false)
        }, 8000)
    }, [])

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://platform.twitter.com/widgets.js";
        script.async = true;
        script.charset = "utf-8"
        document.getElementById('twArea').appendChild(script)
    }, [])


    return (
        <Flex w="100%" direction="column">
            <Helmet>
                <title>Our Tweets - LHO</title>
            </Helmet>
            <Flex w="100%" minH={["30vh", "40vh"]} backgroundColor="#000" backgroundImage={twCover} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="center center">
                <Flex w="100%" h="100%" bg="rgba(0,0,0,0.7)" direction="column" justify="center" px={["5", "8%"]} align="center" color="#fff" py="50px">
                    <Flex mt={["12", "6"]} data-aos="fade-down" w={["71px", "81px"]} h={["70px", "80px"]} bgColor="rgba(100, 100, 100, .4)" mb={["3", "4"]} backgroundImage={logo} backgroundSize="65%" backgroundRepeat="no-repeat" borderRadius="100%" backgroundPosition="center center">
                    </Flex>
                    <Text data-aos="fade-down" fontSize={["27px", "60px"]} fontWeight="700">LHO Daily <Text fontWeight="300" as="span">Tweets</Text></Text>
                </Flex>
            </Flex>

            <Flex pt={["40px", "50px"]} bg="rgba(100, 100, 100, 0.08)" w="100%" align="center" direction="column" px={["5", "8%"]}> 
                <Flex w="100%" align="center" direction="column">
                    <Text data-aos="fade-down" fontSize={["26px", "40px"]}><Text fontWeight="700" as="span">Our</Text> Handle</Text>
                    <Text textAlign="center" fontSize={["13px", "14px"]} data-aos="fade-up" mt="2" px={["5", "0"]} color="rgb(102, 112, 133)">“Caring for women” Our recent activities happening on our twitter timeline</Text>
                </Flex>
                {loading2 && 
                <Flex justify="center" py="12"><Spinner color="#000" emptyColor="lightgrey" size="xl" /></Flex>}
            </Flex>
            <Text px={["4", "18%"]} pt="8" pb={["50px", "70px"]} bgColor="rgba(100, 100, 100, 0.08)">
                <a class="twitter-timeline"
                    href="https://twitter.com/Drweloveu">
                </a>
            </Text>
            <Text id="twArea"></Text>
        </Flex>
    )
}