import { Flex, Image, Text, Spinner, } from "@chakra-ui/react"
import { useEffect, useState, } from "react"
import { useHistory } from "react-router-dom";
import p1 from "./p1.png"
import p2 from "./p2.png"
import p3 from "./p3.png"
import {  signOut } from "firebase/auth";
import { auth, db } from "./firebase/firebase"
import { doc, setDoc, query, orderBy, limit, collection, getDocs, where, getDoc  } from "firebase/firestore";
import Toast from "./toast";
import { Helmet } from "react-helmet";

export default function Register({ isLogged, setIsLogged }) {
    const history = useHistory()
    useEffect(() => {
        if(!isLogged) {
            history.push("/sign-in")
        }
    }, [])
    const [mod1, setMod1] = useState(false)
    const [mod2, setMod2] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)

    
    const calculate_age = (birthday) => {
        const ageDifMs = Date.now() - new Date(birthday).getTime();
        const ageDate = new Date(ageDifMs);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    const data = JSON.parse(sessionStorage.getItem("lho-userdata"))

    const isAppointed = data?.appointment ? true : false

    const ourUsers = []
    const [ourUsersData, setOurUsersData] = useState([])

    useEffect(async () => {
        setLoading2(true)
        const docRef = doc(db, "users", sessionStorage.getItem("lho-usermail"))
        const docSnap = await getDoc(docRef)
        const myRegDay = docSnap.data()?.regday

        const q = query(collection(db, "users"), where("regday", "!=", parseInt(myRegDay)), orderBy("regday", "desc"), limit(4))
        const querySnapshot = await getDocs(q)
        querySnapshot.forEach((docz) => {
            ourUsers.push([docz.id, docz.data()])
        })
        setOurUsersData(ourUsers)
        setLoading2(false)
    }, [])



    async function logout() {
        setLoading(true)              
        await signOut(auth).then(() => {
            sessionStorage.removeItem("lho-login")
            sessionStorage.removeItem("lho-usermail")
            sessionStorage.removeItem("lho-userdata")
            setMod1(false)
            setIsLogged(false)
            history.push("/sign-in")
        }).catch((error) => {
        })
        setLoading(false)
    }

    async function cancelAppointmnet() {
        setLoading(true)
        await setDoc(doc(db, "users", sessionStorage.getItem("lho-usermail")), {       
            regday: Date.now(),
            firstname: data?.firstname,
            lastname: data?.lastname,
            dob: data?.dob,
            gender: data?.gender,
            phone: data?.phone,
            zplink: data?.zplink
        })
        sessionStorage.setItem("lho-userdata", JSON.stringify({
            firstname: data?.firstname,
            lastname: data?.lastname,
            dob: data?.dob,
            gender: data?.gender,
            phone: data?.phone,
            zplink: data?.zplink
        }))
        Toast("Appointment Cacelled successfully!", "success")
        setLoading(false)
        history.push("/")
        history.push("/dashboard")
    }
    
    return (
        <Flex w="100%" direction="column">
            <Helmet>
                <title>My Dashboard - LHO</title>
            </Helmet>
            <Flex w="100%" minH={["23vh", "30vh"]} backgroundColor="#000" backgroundImage={data?.zplink} backgroundSize="100%" backgroundRepeat="no-repeat" backgroundPosition="center center">
                <Flex w="100%" h="100%" bg="rgba(0,0,0,0.55)" backdropFilter="blur(4px)">
                </Flex>
            </Flex>

            <Flex w="100%" px={["4", "8%"]} zIndex="10" direction={["column", "row"]}>
                <Flex data-aos="fade-up" flex="1" direction="column" align="center" boxShadow={["0 1px 10px 4px rgba(0,0,0,.08)", "none"]} mt={["5", "0"]} pb={["8", "0"]} borderRadius={["10px", "0"]}>
                    <Flex w={["170px", "200px"]} h={["170px", "200px"]} mt={["-70px", "-60px"]} border="5px solid #fff" borderRadius="100%" backgroundColor="#000" backgroundImage={data?.zplink} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="center center" boxShadow="0 1px 10px 4px rgba(0,0,0,.15)"></Flex>
                    
                    <Text mt={["3", "4"]} data-aos="fade-down" fontSize={["26px", "30px"]} textTransform="capitalize" wordBreak="break-all" textAlign="center"><Text fontWeight="700" as="span" wordBreak="break-all">{data?.firstname}</Text> {data?.lastname}</Text>
                    <Flex align="center" data-aos="fade-up" fontSize={["14px", "16px"]} mt='1' color="rgb(102, 112, 133)" wordBreak="break-all"><i className="mdi mdi-email-outline" style={{ marginRight: "5px"}} textAlign="center"></i> {sessionStorage.getItem("lho-usermail")}</Flex>

                    <Flex mt="8" align="center">
                            <Text w="50px" h="2px" bg="#000" mr="2" display={["none", "flex"]}></Text>
                            <Flex display={["flex", "none"]} mr="4" border="2px solid #000" fontSize={["13px", "15px"]} padding={["10px 30px", "18px 35px"]} cursor="pointer" borderRadius="30px" color="#000" fontWeight="700" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => history.push("/booking")}>Book <Text ml="2">➜</Text></Flex>
                            <Flex border="2px solid #000" fontSize={["13px", "15px"]} padding={["10px 30px", "18px 35px"]} cursor="pointer" borderRadius="30px" color="#000" fontWeight="700" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => {
                                setMod1(true)
                            }}>Sign Out <Text ml="2">➜</Text></Flex>
                        </Flex>
                </Flex>


                <Flex data-aos="fade-down" w={["100%", "43%"]} ml={["0", "12"]} mt={["8", "12"]} borderRadius={["10px", "12px 0 0 12px"]} boxShadow="0 1px 10px 4px rgba(0,0,0,.08)" px={["5", "8"]} py="10" direction="column" bg="linear-gradient(135deg, rgb(9, 32, 63) 0%, rgb(83, 120, 149) 100%)" color="#fff">
                    <Text textAlign={["center", "left"]} data-aos="fade-down" fontSize={["26px", "30px"]}><Text fontWeight="700" as="span">My LHO </Text>Profile</Text>

                    <Flex mt="8" w={["100%", "100%"]}align="center" bg="rgba(205,205,255,.18)" borderRadius="8px" py="3" px="4">
                        <Image src={p1} w="40px" />
                        <Text ml="5" wordBreak="break-all"><Text as="span" fontWeight="600">Phone Number:</Text> {data?.phone}</Text>
                    </Flex>

                    <Flex mt="5" w={["100%", "90%"]} align="center" bg="rgba(205,205,255,.18)" borderRadius="8px" py="3" px="4">
                        <Image src={p2} w="40px" />
                        <Text ml="5" textTransform="capitalize"><Text as="span" fontWeight="600">Gender:</Text> {data?.gender}</Text>
                    </Flex>

                    <Flex mt="5" w={["100%", "80%"]} align="center" bg="rgba(205,205,255,.18)" borderRadius="8px" py="3" px="4">
                        <Image src={p3} w="40px" />
                        <Text ml="5"><Text as="span" fontWeight="600">Age:</Text> {calculate_age(data?.dob)} (Yrs), {data?.dob}</Text>
                    </Flex>
                </Flex>

                
                <Flex data-aos="fade-up" w={["100%", "28%"]} ml={["0", "4"]} direction="column" py="12" boxShadow="0 1px 10px 4px rgba(0,0,0,.08)" bg="#fff" borderRadius={["10px", "0 12px 12px 0"]} mt={["8", "12"]} px="6">
                    <Flex data-aos="fade-down" justify="center" align="center" fontWeight="700" w="51px" h="50px" fontSize="30px" bg="linear-gradient(135deg, rgb(94, 2, 30) 0%, rgb(201, 4, 63) 100%)" borderRadius="100%" color="#fff"><i className="mdi mdi-bell-outline"></i></Flex>

                    <Text data-aos="fade-up" mt="4" fontSize={["26px", "20px"]}><Text fontWeight="700" as="span">My </Text>Appointment</Text>
                    
                    {
                        !isAppointed ?
                        <Flex align='center' mt="8" mb="8" pb="3" borderBottom="1px solid rgb(202, 202, 205)" data-aos="fade-up">
                            <Text fontSize="25px"><i className="mdi mdi-medical-bag"></i></Text>
                            <Text ml="3"><Text fontWeight="700">No <Text fontWeight="400" as="span">Appointment</Text></Text></Text>
                        </Flex>
                        :
                        <Flex w='100%' direction="column" mt="8" data-aos="fade-up">
                            <Flex align='center' mb="3" pb="3" borderBottom="1px solid rgb(202, 202, 205)">
                                <Text fontSize="25px"><i className="mdi mdi-calendar-text"></i></Text>
                                <Text ml="3">{data?.appointment}</Text>
                            </Flex>
                            <Flex align='center' mb="3" pb="3" borderBottom="1px solid rgb(202, 202, 205)">
                                <Text fontSize="25px"><i className="mdi mdi-clock"></i></Text>
                                <Text ml="3">{data?.thetime}</Text>
                            </Flex>
                            <Flex align='center' mb="3" pb="3" borderBottom="1px solid rgb(202, 202, 205)">
                                <Text fontSize="25px"><i className="mdi mdi-human-handsdown"></i></Text>
                                <Text ml="3">{data?.doctor}</Text>
                            </Flex>
                            <Flex align='center' mb="8" pb="3" borderBottom="1px solid rgb(202, 202, 205)">
                                <Text fontSize="25px"><i className="mdi mdi-medical-bag"></i></Text>
                                <Text ml="3">{data?.service}</Text>
                            </Flex>
                        </Flex>
                    }
                    <Flex mt="auto" border="2px solid #000" fontSize={["13px", "15px"]} padding={["10px 30px", "18px 35px"]} cursor="pointer" borderRadius="30px" color="#000" fontWeight="700" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => isAppointed ? setMod2(true) : history.push("/booking")}>{isAppointed ? "Cancel Appointment" : "Book Now"} <Text ml="2">➜</Text></Flex>
                </Flex>
            </Flex>


            <Flex w="100%" direction="column" px={["4", "8%"]}>
                <Flex w="100%" mt="60px" pt="40px" px={["0", "10%"]} pb="60px" direction="column" borderTop="1px solid rgb(202, 202, 205)">
                    <Text data-aos="fade-down" fontSize={["26px", "40px"]}><Text fontWeight="700" as="span">Quick </Text>Actions</Text>
                    <Text data-aos="fade-up" mt="1" fontSize={["13px", "15px"]} color="rgb(102, 112, 133)" pr={["2", "35%"]} textAlign="left">Need a free virtual health care service or you need free consultation wiht a Doctor kindly select an action below</Text>

                    <Flex mt="8" w="100%" justify="space-between" direction={['column', "row"]} data-aos="zoom-in">
                        <Flex py="10" borderRadius="10px" bg="linear-gradient(135deg, rgb(94, 2, 30) 0%, rgb(201, 4, 63) 100%)" w={["100%", "48.5%"]} px="8" direction="column" align="center" color="#fff" justify="center" cursor="pointer" _hover={{ transform: "scale(0.95)"}} transition="300ms ease-in-out" onClick={() => {
                            Toast("Send your message to our live chat support for instant response", "success")
                            setTimeout(function() {
                                window.tidioChatApi.open()
                            }, 1000)
                        }}>
                            <Flex justify="center" align="center" fontWeight="700" w={["141px", "151px"]} h={["140px", "150px"]}  fontSize={["85px", "100px"]} bg="rgba(255,255,255,.18)" borderRadius="100%" color="#fff"><i className="mdi mdi-phone-plus"></i></Flex>
                            <Text data-aos="fade-down" mt="8" fontSize={["24px", "20px"]}><Text fontWeight="700" as="span">Instant </Text>Support</Text>
                        </Flex>

                        <Flex mt={["8", "0"]} py="10" borderRadius="10px" bg="linear-gradient(135deg, rgb(252, 162, 101) 0%, rgb(173, 72, 5) 100%)" w={["100%", "48.5%"]} direction="column" align="center" color="#fff" justify="center" cursor="pointer" _hover={{ transform: "scale(0.95)"}} transition="300ms ease-in-out" onClick={() => history.push("/booking")}>
                            <Flex justify="center" align="center" fontWeight="700" w={["141px", "151px"]}  h={["140px", "150px"]} fontSize={["85px", "100px"]} bg="rgba(255,255,255,.18)" borderRadius="100%" color="#fff"><i className="mdi mdi-heart-pulse"></i></Flex>
                            <Text data-aos="fade-down" mt="8" fontSize={["24px", "20px"]}><Text fontWeight="700" as="span">Schedule </Text>Consultation</Text>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>

            <Flex w="100%" direction="column" px={["5", "8%"]}>
                <Flex w="100%" mt={["0", "20px"]} pt="40px" px={["0", "10%"]} pb="60px" direction="column" borderTop="1px solid rgb(202, 202, 205)">
                    <Text data-aos="fade-down" fontSize={["26px", "40px"]}><Text fontWeight="700" as="span">Our </Text>Community</Text>
                    <Text data-aos="fade-up" mt="1" fontSize={["13px", "15px"]} color="rgb(102, 112, 133)" pr={["0", "35%"]} textAlign="left">You can engage our twitter channel and join other members of the community in empowering and providing support for the girl-child and women globally</Text>
                    <Flex w='100%' justify="flex-end" mt={["12", "8"]} data-aos="fade-up">
                        <Flex align="center" data-aos="fade-up">
                            <Text w={["30px", "50px"]} h="2px" bg="#000" mr="2"></Text>
                            <Flex mr={["2", "4"]} border="2px solid #000" padding={["10px 25px", "16px 34px"]} cursor="pointer" borderRadius="30px" color="#000" fontWeight="600" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => window.open("https://twitter.com/Drweloveu", "_BLANK")}>Follow Community <Text ml="2">➜</Text></Flex>
                        </Flex>
                    </Flex>

                </Flex>
            </Flex>
            
            <Text data-aos="fade-down" mt={["3", "0"]} fontSize={["24px", "40px"]} px={["4", "16%"]}  mb="7" ml={["1", "0"]}><Text fontWeight="700" as="span">Suggested</Text> Members</Text>
            <Flex w='100%' justify="center" px={["4", "8%"]} pb="60px" flexWrap="wrap" direction={["column", "row"]}>
                {
                    loading2 ?
                    <Flex justify="center" align="center" py="12">
                        <Spinner size="xl" emptyColor="#000" color="lightgrey" />
                    </Flex>
                    :
                    ourUsersData.map((item, index) => (     
                        <Flex key={index} mb={["4", "0"]} flex='1' mx={["0", "4"]} boxShadow="0 1px 10px 4px rgba(0,0,0,.15)" px="5" py="7" direction="column" align="center" display={sessionStorage.getItem("lho-usermail") === item[0] ? "none" : "flex"} data-aos="fade-up" borderRadius="5px">
                            <Flex w={["170px", "140px"]} h={["170px", "140px"]} borderRadius="100%" backgroundImage={item[1]?.zplink} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="center center" boxShadow="0 1px 10px 4px rgba(0,0,0,.15)" border="5px solid #fff"></Flex>
                            <Text mt="3" fontSize="17px" textTransform="capitalize" wordBreak="break-all">@<Text as="span" fontWeight="700">{item[1]?.firstname}</Text> {item[1]?.lastname}</Text>
                            <Flex mt="1" align="center"><i className="mdi mdi-human-handsdown"></i><Text ml="2">{item[1]?.gender}</Text></Flex>
                            <Text mt="auto"></Text>
                            <Flex display={["none", "flex"]} mt="6" w="100%" border="2px solid #000" fontSize={["13px", "15px"]} padding={["12px 30px", "13px 35px"]} cursor="pointer" borderRadius="30px" color="#000" fontWeight="700" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => {
                                Toast("Reach out is via email!")
                                setTimeout(function() {
                                    window.open("mailto: "+item[0]+"?subject=LHO Community&body=Hello "+item[1]?.firstname+", I am "+data?.firstname+" from Love for health...", "_BLANK")
                                }, 1000)
                            }}>Reach Out <Text ml="2">➜</Text></Flex>

                            <Flex display={["flex", "none"]} mt="6" w="100%" border="2px solid #000" fontSize={["13px", "15px"]} padding={["12px 30px", "13px 35px"]} cursor="pointer" borderRadius="30px" color="#000" fontWeight="700" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => window.open("mailto: "+item[0]+"?subject=LHO Community&body=Hello "+item[1]?.firstname+", I am "+data?.firstname+" from Love for health...", "_BLANK")}>Reach Out <Text ml="2">➜</Text></Flex>
                        </Flex>
                    ))
                }
            </Flex>


            {
                mod1 &&  
                <Flex w="100%" h="100%" position="fixed" top="0" left="0" zIndex="100000" bg="rgba(0,0,0,0.5)" backdropFilter="blur(12px)" justify="center" align="center">
                    <Flex w={["90%", "550px"]} direction="column" bg="#fff" borderRadius="8px" data-aos="fade-up" data-aos-duration="700">
                        <Text textAlign="center" py="12" px="8" fontWeight="500">Are you sure you want to logout?</Text>
                        <Flex justify="space-between" py="5" px="8">
                            <Flex border="2px solid #000" fontSize={["13px", "15px"]} padding={["10px 30px", "18px 35px"]} cursor="pointer" borderRadius="30px" color="#000" fontWeight="700" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => setMod1(false)}>Cancel <Text ml="2">➜</Text></Flex>

                            <Flex border="2px solid #000" bg="#000" fontSize={["13px", "15px"]} padding={["10px 30px", "18px 35px"]} cursor="pointer" borderRadius="30px" color="#fff" fontWeight="700" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => logout()}>{loading ? <Spinner color="#fff" emptyColor="lightgrey" /> : <>Yes <Text ml="2">➜</Text></>}</Flex>
                        </Flex>
                    </Flex>
                </Flex>
            }

            {
                mod2 &&  
                <Flex w="100%" h="100%" position="fixed" top="0" left="0" zIndex="100000" bg="rgba(0,0,0,0.5)" backdropFilter="blur(12px)" justify="center" align="center">
                    <Flex w={["90%", "550px"]} direction="column" bg="#fff" borderRadius="8px" data-aos="fade-up" data-aos-duration="700">
                        <Text textAlign="center" py="12" px="8" fontWeight="500">Are you sure you want to cancel your appointment?</Text>
                        <Flex justify="space-between" py="5" px="8">
                            <Flex border="2px solid #000" fontSize={["13px", "15px"]} padding={["10px 30px", "18px 35px"]} cursor="pointer" borderRadius="30px" color="#000" fontWeight="700" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => setMod2(false)}>Cancel <Text ml="2">➜</Text></Flex>

                            <Flex border="2px solid #000" bg="#000" fontSize={["13px", "15px"]} padding={["10px 30px", "18px 35px"]} cursor="pointer" borderRadius="30px" color="#fff" fontWeight="700" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => cancelAppointmnet()}>{loading ? <Spinner color="#fff" emptyColor="lightgrey" /> : <>Yes <Text ml="2">➜</Text></>}</Flex>
                        </Flex>
                    </Flex>
                </Flex>
            }
        </Flex>
    )
}