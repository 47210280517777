import { Flex, Image, Text, Spinner, } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import a1 from "./a1.jpg"
import axios from "axios"
import logo from "./logo.png"
import hlt from "./hlt.jpg"
import { Helmet } from "react-helmet"


export default function Articles() {
    const [newsData, setNewsData] = useState("")
    const [loading, setLoading] = useState(false)

    const [page, setPage] = useState(0)
    useEffect(async () => {
        const url = 'https://api.nytimes.com/svc/search/v2/articlesearch.json?q=healthcare&fq=women&api-key=VvG8seu6yGiJJzm95XyvV9kjVwXtrecw&sort=newest&page='+page
        setLoading(true)
        const res = await axios.get(url)
        setNewsData(res?.data?.response?.docs)
        setLoading(false)
    }, [page])
    const [mod1, setMod1] = useState(false)
    const [modArr, setModArr] = useState("")

    
    
    return (
        <Flex w="100%" direction="column">
            <Helmet>
                <title>Daily Articles - LHO</title>
            </Helmet>
            <Flex w="100%" minH={["35vh", "40vh"]} backgroundColor="#000" backgroundImage={a1} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="center center">
                <Flex w="100%" h="100%" bg="rgba(0,0,0,0.7)" direction="column" justify="center" px={["5", "8%"]} align="center" color="#fff" py={["40px", "40px"]}>
                    <Flex mt={["12", "6"]} data-aos="fade-down" w={["71px", "81px"]} h={["70px", "80px"]} bgColor="rgba(100, 100, 100, .4)" mb={["3", "4"]} backgroundImage={logo} backgroundSize="65%" backgroundRepeat="no-repeat" borderRadius="100%" backgroundPosition="center center">
                    </Flex>
                    <Text data-aos="fade-down" fontSize={["27px", "60px"]} fontWeight="700">LHO Daily <Text fontWeight="300" as="span">Health</Text></Text>
                    <Text fontSize={["15px", "20px"]} data-aos="fade-up" color="rgb(240,240,240)" textAlign="center" px={["5", "18%"]}>
                        Daily health related articles from numerous medical writers and authors.
                    </Text>
                </Flex>
            </Flex>
            

            <Flex minH="20vh" py={["40px", "80px"]} bg="#fff" w="100%" align="center" direction="column" px={["5", "8%"]}>

            <Flex direction="column" align="center" w="100%">
                    <Text data-aos="fade-down" fontSize={["26px", "40px"]} fontWeight="700">Daily <Text fontWeight="300" as="span">Articles</Text></Text>
                    <Text mb="10" mt="2" fontSize={["13px", "14px"]} data-aos="fade-up" color="rgb(102, 112, 133)" textAlign="center" px={["5", "18%"]}>
                        Daily health related articles from numerous medical writers and authors.
                    </Text>
                    {
                        loading ? 
                        <Flex justify="center" py="12"><Spinner color="#000" emptyColor="lightgrey" size="xl" /></Flex> :
                        Array.isArray(newsData) &&
                        newsData.map((item, index) => (
                            <Flex direction={["column", "row"]} key={index} data-aos="fade-up" w="100%" boxShadow="0px 0px 10px rgba(0,0,0,0.2)" mb="7">
                                <Flex bgColor="#000" w={["100%", "40%"]} h={["200px", "auto"]} backgroundImage={item?.multimedia[0]?.url ? "https://www.nytimes.com/"+item?.multimedia[0]?.url : hlt} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="center center"></Flex>

                                <Flex flex="1" py={["5", "8"]} px={["5", "8"]} direction="column" bg="#fff">
                                    <Text fontSize={["17px", "22px"]} fontWeight="700" cursor="pointer" _hover={{ textDecoration: "underline" }} onClick={() => {
                                        setModArr(item)
                                        setMod1(true)
                                    }}>{item?.headline?.main}</Text>
                                    <Text mt={["3", "4"]} color="rgb(102, 112, 133)" fontSize={["15px", "18px"]}>{item?.snippet?.substr(0,200)}....</Text>
                                    <Flex w="100%" color="rgb(102, 112, 133)" mt="7" fontSize={["10px", "15px"]} justify={["space-between", "flex-start"]}>
                                        <Flex align="center"><Text fontSize={["20px", "25px"]}><i className="mdi mdi-calendar-clock"></i></Text> <Text ml="2">{item?.pub_date?.substr(0,10)}, {item?.pub_date?.substr(11,5)}</Text></Flex>
                                        <Flex ml="8" align="center"><Text fontSize={["20px", "25px"]}><i className="mdi mdi-account-outline"></i></Text> <Text ml="2" textTransform="capitalize" wordBreak="break-word">{item?.byline?.original ? item?.byline?.original?.toLowerCase() : "Author 0"+index}</Text></Flex>
                                    </Flex>
                                </Flex>
                            </Flex>
                        ))
                    }
                </Flex>
                {!loading && <Flex w="100%" mt={["6", "10"]} justify="center" align="center" data-aos="fade-up">
                    <Text w="50px" h="2px" bg="#000" mr="2"></Text><Flex bg="#fff" border="2px solid #000" padding={["15px 30px", "18px 35px"]} cursor="pointer" borderRadius="30px" color="#000" fontWeight="600" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => {
                        setPage(parseInt(page) + parseInt(1))
                        window.scrollTo(0, 0)
                    }}>Next<Text ml="2">➜</Text></Flex>
                </Flex>}

                {
                    mod1 &&
                    <Flex w="100%" h="100%" position="fixed" top="0" left="0" zIndex="100000" bg="rgba(0,0,0,0.5)" backdropFilter="blur(12px)" justify="center" align="center" py="8">
                        <Flex w={["90%", "800px"]} data-aos="fade-up" bg="#fff" direction="column">
                            <Flex bgColor="#000" w="100%" h="200px" backgroundImage={modArr?.multimedia[0]?.url ? "https://www.nytimes.com/"+modArr?.multimedia[0]?.url : hlt} backgroundSize="cover" backgroundRepeat="no-repeat" justify="flex-end" pt="4" pr="4" backgroundPosition="center center">
                                <Flex bg="#fff" justify="center" align="center" color="#000" fontSize={["20px", "30px"]} cursor="pointer" borderRadius="100%" h={["40px", "50px"]} w={["41px", "51px"]} onClick={() => setMod1(false)}><i className="mdi mdi-close"></i></Flex>
                            </Flex>

                            <Flex flex="1" mt="6" p={["8", "12"]} maxH={["60vh", "50vh"]} overflowY="scroll" direction="column" bg="#fff">
                                <Text fontSize={["17px", "22px"]} fontWeight="700" cursor="pointer">{modArr?.headline?.main}</Text>
                                <Text mt={["3", "4"]} color="rgb(102, 112, 133)" fontSize={["15px", "18px"]}>{modArr?.lead_paragraph}</Text>
                                <Flex w="100%" color="rgb(102, 112, 133)" mt="7" fontSize={["10px", "15px"]} justify={["space-between", "flex-start"]}>
                                    <Flex align="center"><Text fontSize={["20px", "25px"]}><i className="mdi mdi-calendar-clock"></i></Text> <Text ml="2">{modArr?.pub_date?.substr(0,10)} @{modArr?.pub_date?.substr(11,5)}</Text></Flex>
                                    <Flex ml="8" align="center"><Text fontSize={["20px", "25px"]}><i className="mdi mdi-account-outline"></i></Text> <Text ml="2" textTransform="capitalize" wordBreak="break-word">{modArr?.byline?.original ? modArr?.byline?.original?.toLowerCase() : "Author 00"}</Text></Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                }
            </Flex>

        </Flex>
    )
}