import { Flex, Image, Text, Spinner, } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import c1 from "./c1.jpg"
import phone from "./phone.png"
import Toast from "./toast"
import logo from "./logo.png"
import { Helmet } from "react-helmet"



export default function Contact() {
    return (
        <Flex w="100%" direction="column">
            <Helmet>
                <title>Contact Us - LHO</title>
            </Helmet>
            <Flex w="100%" minH={["60vh", "80vh"]} backgroundColor="#000" backgroundImage={c1} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="center center">
                <Flex w="100%" h="100%" bg="rgba(0,0,0,0.7)" direction="column" justify="center" px={["5", "8%"]} align="center" color="#fff" py="50px">
                    <Flex mt={["12", "0"]} data-aos="fade-down" w={["71px", "81px"]} h={["70px", "80px"]} bgColor="rgba(100, 100, 100, .4)" mb={["3", "4"]} backgroundImage={logo} backgroundSize="65%" backgroundRepeat="no-repeat" borderRadius="100%" backgroundPosition="center center">
                    </Flex>
                    <Text data-aos="fade-down" fontSize={["27px", "60px"]} fontWeight="700">Need our <Text fontWeight="300" as="span">help?</Text></Text>
                    <Text fontSize={["15px", "20px"]} data-aos="fade-up" color="rgb(240,240,240)" textAlign="center" px={["0", "18%"]}>Recent statistics indicate approximately 512 deaths per 100,000 live births, emphasizing the urgent need for improved healthcare access and skilled professionals</Text>
                    <Flex mt="10" align="center" data-aos="fade-up">
                        <Text w={["20px", "50px"]} h="2px" bg="#fff" mr="2"></Text>
                        <Flex border="2px solid #fff" padding={["10px 30px", "18px 35px"]} cursor="pointer" borderRadius="30px" color="#fff" fontWeight="700" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => {
                            window.tidioChatApi.open()
                        }}>Instant Assistance<Text ml="2">➜</Text></Flex>
                        <Text w={["20px", "50px"]} h="2px" bg="#fff" ml="2"></Text>
                    </Flex>
                </Flex>
            </Flex>

            
            <Flex py={["40px", "60px"]} bg="rgb(250,250,252)" align="center" px={["5", "10%"]} direction={["column", "row"]}>
                <Flex w={["100%", "55%"]} direction="column" pr={["0", "10%"]}>
                    <Text fontSize={["26px", "40px"]} fontWeight="700">Reach <Text fontWeight="300" as="span">Out</Text></Text>
                    <Text mt="2" mb="8" color="rgb(102, 112, 133)" fontSize={["13px", "15px"]}>Send us a message. Our team of health professionals will reach out to you or contact the live chat for instant response.</Text>

                    <Flex w="100%" direction="column" data-aos="fade-up">
                        <Flex align="center" mb="2" fontSize="18px"><i className="mdi mdi-account-outline"></i><Text fontSize="14px" ml="2">Fullname</Text></Flex>
                        <input style={{ width: "100%", border: "1px solid #000", padding: "15px 28px", borderRadius: "8px" }} placeholder="Enter your fullname" />
                        <Flex mt="5" align="center" mb="2" fontSize="18px"><i className="mdi mdi-email-outline"></i><Text fontSize="14px" ml="2">Email</Text></Flex>
                        <input style={{ width: "100%", border: "1px solid #000", padding: "15px 28px", borderRadius: "8px" }} placeholder="Enter your email" />
                        <Flex mt="5" align="center" mb="2" fontSize="18px"><i className="mdi mdi-chat-outline"></i><Text fontSize="14px" ml="2">Message</Text></Flex>
                        <textarea style={{ width: "100%", border: "1px solid #000", padding: "15px 28px", borderRadius: "8px", resize: "none", height: "120px" }} placeholder="Enter your message"></textarea>

                        
                        <Flex mt="8" align="center">
                            <Text w="50px" h="2px" bg="#000" mr="2"></Text>
                            <Flex border="2px solid #000" padding={["10px 30px", "18px 35px"]} cursor="pointer" borderRadius="30px" color="#000" fontWeight="700" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => {
                                Toast("Opening Ticket", "success")
                                window.tidioChatApi.open()
                            }}>Send Message <Text ml="2">➜</Text></Flex>
                        </Flex>
                    </Flex>
                </Flex>
                <Flex w={["100%", "45%"]} mt={["8", "0"]} px={["15%", "0"]} data-aos="fade-down">
                    <Image w="100%" src={phone} filter="drop-shadow(0 0px 10px rgba(0,0,0,.55))" />
                </Flex>
            </Flex>

            <Flex w="100%" direction="column" align="center" py={["60px", "100px"]}  px={["5", "12%"]} bg="#fff">
                <Text data-aos="fade-down" fontSize={["26px", "40px"]} fontWeight="700">Get in <Text fontWeight="300" as="span">touch</Text></Text>
                <Text mt="2" color="rgb(102, 112, 133)" textAlign="center" fontSize={["13px", "15px"]} px={["2", "20%"]}>We have a dedicated team of passionate health professionals committed to improving the health and well-being of women and children globally</Text>


                <Flex mt="12" align="baseline" data-aos="fade-up" justify="space-between" px={["0", "20%"]} w="100%">
                    <Text w={["30px", "50px" ]} h="2px" bg="#000"></Text>
                    
                    <Flex direction="column" align="center" onClick={() => window.open("mailto: loveforhealthlho@gmail.com", "_SELF")}>
                        <Flex border="2px solid #000" cursor="pointer" color="#000" fontWeight="700" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(0px, -4px)" }} fontSize={["30px", "50px"]} w={["50px", "75px"]} h={["50px", "75px"]} borderRadius="100%"><i className="mdi mdi-email-outline"></i></Flex>
                        <Text mt="5" fontWeight="600" fontSize={["14px", "15px"]}>Email Us</Text>
                        <Text textAlign="center" mt="1" color="rgb(102, 112, 133)" fontSize={["12px", "14px"]} onClick={() => window.open("mailto: loveforhealthlho@gmail.com", "_SELF")}>email</Text>
                    </Flex>

                    <Text w="50px" h="2px" bg="#000" display={["none", "block"]}></Text>

                    
                    <Flex mx={["auto", "0"]} direction="column" align="center" onClick={() => window.open("tel: +16148978196", "_SELF")}>
                        <Flex border="2px solid #000" cursor="pointer" color="#000" fontWeight="700" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(0px, -4px)" }} fontSize={["30px", "50px"]} w={["50px", "75px"]} h={["50px", "75px"]} borderRadius="100%"><i className="mdi mdi-phone-outline"></i></Flex>
                        <Text textAlign="center" mt="5" fontWeight="600" fontSize={["14px", "15px"]}>Call Us</Text>
                        <Text mt="1" textAlign="center" color="rgb(102, 112, 133)" fontSize={["12px", "14px"]}>+1 (614) 897 - 8196</Text>
                    </Flex>

                    <Text w="50px" h="2px" bg="#000" display={["none", "block"]}></Text>

                    <Flex direction="column" align="center">
                        <Flex border="2px solid #000" color="#000" fontWeight="700" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(0px, -4px)" }} fontSize={["30px", "50px"]} w={["50px", "75px"]} h={["50px", "75px"]} borderRadius="100%"><i className="mdi mdi-map-marker"></i></Flex>
                        <Text textAlign="center" mt="5" fontWeight="600" fontSize={["14px", "15px"]}>Location</Text>
                        <Text mt="1" textAlign="center" color="rgb(102, 112, 133)" fontSize={["12px", "14px"]}>Ohio, USA</Text>
                    </Flex>

                    <Text w={["30px", "50px" ]} h="2px" bg="#000"></Text>
                </Flex>
            </Flex>

        </Flex>
    )
}