import { Flex, Image, Text, Spinner } from "@chakra-ui/react"
import { useEffect, useState, } from "react"
import { useHistory, useLocation } from "react-router-dom";
import tryImage from "./try.svg"
import logo from "./logo.png"
import {  signInWithEmailAndPassword  } from 'firebase/auth';
import { auth, db } from './firebase/firebase';
import Toast from "./toast";
import { doc, getDoc } from "firebase/firestore";
import { Helmet } from "react-helmet";


export default function SignIn({ isLogged, setIsLogged }) {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    useEffect(() => {
        if(isLogged) {
            history.push("/dashboard")
        }
    }, [])

    async function login() {
        if(email !== "" && password !== "") {
            setLoading(true)
            await signInWithEmailAndPassword(auth, email, password)
            .then(async () => {
                const docRef = doc(db, "users", email)
                const docSnap = await getDoc(docRef)
                if (docSnap.exists()) {
                    sessionStorage.setItem("lho-userdata", JSON.stringify(docSnap.data()))
                    sessionStorage.setItem("lho-usermail", email)
                    sessionStorage.setItem("lho-login", true)
                    setIsLogged(true)
                    history.push("/dashboard")
                    Toast("Welcome Back "+docSnap.data()?.firstname+"!", "success")
                }
                else {
                    Toast("An error occured while trying to sign you in", "error")
                }
                setLoading(false)
            })
            .catch((error) => {
                console.log(error)
                const errorMessage = error.message
                const showError = errorMessage === "Firebase: Error (auth/user-not-found)." ? "Your details has not being registered with LHO. Kindly regsiter by clicking register here below" : errorMessage === "Firebase: Error (auth/wrong-password)." ? "Your password is incorrect" : errorMessage === "Firebase: Error (auth/invalid-email)." ? "The email you provided is not valid" : errorMessage
                Toast(showError, "error")
            })
            setLoading(false)
        }
        else {
            Toast("Kindly fill in your username and password to continue", "error")
        }
    }
    return (
        <Flex w="100%" h="100vh" align="center" justify="center" bgColor="rgb(140,80,92)" backgroundImage={tryImage} backgroundSize={["100%", "80%"]} backgroundRepeat="no-repeat" backgroundPosition="center center">  
            <Helmet>
                <title>Sign In - LHO</title>
            </Helmet>
            <Flex w={["92%", "580px"]} borderRadius="8px" bg="#fff" h={["75vh", "84vh"]} direction="column" px="6" py="8" justify="center">
                <Flex data-aos="fade-down" w={["41px", "51px"]} h={["40px", "50px"]} bgColor="rgba(100, 100, 100, .1)" mb={["3", "4"]} backgroundImage={logo} backgroundSize="65%" backgroundRepeat="no-repeat" borderRadius="100%" backgroundPosition="center center"></Flex>
                <Text data-aos="fade-down" fontSize={["24px", "30px"]}><Text fontWeight="700" as="span">Sign</Text> In - LHO</Text>
                <Text mt="10">
                    <Text mb="2">Email address</Text>
                    <input style={{ width: "100%", padding: "10px 20px", borderRadius: "5px", background: "#fff", border: "2px solid #000"}} type="email" placeholder="Enter your email address" onChange={(e) => setEmail(e.target.value)} />
                </Text>
                <Text mt="5">
                    <Text mb="2">Password</Text>
                    <input style={{ width: "100%", padding: "10px 20px", borderRadius: "5px", background: "#fff", border: "2px solid #000"}} type="password" placeholder="Enter your account password" onChange={(e) => setPassword(e.target.value)} />
                </Text>
                <Flex mt={["8", "10"]} align="center" data-aos="fade-up">
                    <Text w={["30px", "50px"]} h="2px" bg="#000" mr="2"></Text>
                    <Flex flex="1" border="2px solid #000" padding={["10px 25px", "12px 34px"]} cursor="pointer" borderRadius="30px" color="#fff" bg="#000" fontWeight="600" align="center" justify="center" transition="150ms ease-in-out" fontSize={["12px", "14px"]} _hover={{ transform: "translate(4px, 0px)" }} onClick={() => login()}>{loading ? <Spinner color="#fff" emptyColor="lightgrey" /> : <>Sign In <Text ml="2">➜</Text></>}</Flex>
                </Flex>
                <Text mt="10">Don't have an account? <Text cursor="pointer" ml="3" color="#0e8a3a" as="span" onClick={() => history.push("/register")}>Register Here</Text></Text>
            </Flex>
        </Flex>
    )
}