import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";


const firebaseConfig = {
    apiKey: "AIzaSyDkVGU06IHEEDybCHYwHe4pWf7EFYev1UY",
    authDomain: "lho-life-b3831.firebaseapp.com",
    projectId: "lho-life-b3831",
    storageBucket: "lho-life-b3831.appspot.com",
    messagingSenderId: "73667262192",
    appId: "1:73667262192:web:06a949c26f83ec7fd2b435",
    measurementId: "G-20J1LKE1WN"
};
  
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export default app;